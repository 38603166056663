import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { colors } from '../styles';
import { Svg } from './styles';

const EmailIcon = ({ color = colors.BLACK, hoverColor }: IconProps): ReactElement => (
  <Svg viewBox="0 0 1024 1024" $hoverColor={hoverColor}>
    <g fill={color}>
      <path d="m520 517 459-373H32l461 370c9 8 22 8 27 3M0 880h1024V253L577 617a100 100 0 0 1-139-3L-1 263v617z" />
    </g>
  </Svg>
);

export default EmailIcon;
