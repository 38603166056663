import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import dynamic from 'next/dynamic';
import { getCookie } from 'cookies-next';
import { useTranslation } from 'next-i18next';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import { media, colors } from '../../core/styles';
import { Typography } from '../../core/typography/Typography';
import type { HeaderItemPosition } from '../sideBar/SideNavHeader';

import { isMatchLocaleCountry, isValidLocale } from '../../../../utilities/ssr';
import type { GlobalLocaleSelector } from '../../../../amplienceTypes/schemas/imported/global-locale-selector-schema';
import type { HeaderOpenModalProps } from '../../core/layouts/MasterLayout';
import type { LocaleSelectorModalProps } from './LocaleSelectorModal';
import { storageAvailable } from '../../../../utilities/helpers';
import { useDataLayerContext } from '../../../../utilities/context/static/DataLayerContext';
import { parseLocaleSelectorDataLayer } from '../../../../utilities/vendors';

export interface LocaleSelectorButtonProps {
  buttonPosition?: HeaderItemPosition;
}

const LocaleSelectorModal = dynamic<LocaleSelectorModalProps>(
  () => import('./LocaleSelectorModal').then(mod => mod.LocaleSelectorModal),
  { ssr: false }
);

const S = {
  LocaleSelectorButton: styled.button<{ $buttonPosition: HeaderItemPosition }>`
    display: ${({ $buttonPosition }) => ($buttonPosition === 'main-header' ? 'none' : 'flex')};
    border: none;
    height: 54px;
    position: absolute;
    padding: 0 10px;
    top: 0;
    right: 0;
    text-decoration: none;
    cursor: pointer;
    align-items: center;

    &:hover,
    &:focus {
      span {
        color: ${colors.DARK_GREY};
      }
    }

    @media ${media.greaterThan('sm')} {
      display: flex;
      gap: 4px;
      position: relative;
      width: auto;
    }
  `,

  Flag: styled.span<{ $country: string }>`
    background: url(/_fes/${process.env.BUILD_TIME}/img/flags@2x/${({ $country }) => $country}.png)
      no-repeat center;
    width: 16px;
    height: 16px;
    display: inline-block;
    background-size: contain;
  `,

  Language: styled(Typography)`
    line-height: 54px;
    margin-left: 5px;
    color: ${colors.WHITE};
    text-transform: uppercase;
  `,
};

export const LocaleSelectorButton = ({
  buttonPosition = 'main-header',
  title,
  btnClose,
  btnContinue,
  cartWarning,
  openedModal,
  setOpenedModal,
}: LocaleSelectorButtonProps & GlobalLocaleSelector & HeaderOpenModalProps): ReactElement => {
  const [cookieLanguage, setCookieLanguage] = useState<string>();
  const [cookieCountry, setCookieCountry] = useState<string>();

  const { locale, country, language } = useAppContext();
  const { pushToDataLayer } = useDataLayerContext();

  const { t } = useTranslation('common', { keyPrefix: 'globalLocaleSelector' });

  useEffect(() => {
    const isLocalStorageAvailable = storageAvailable('localStorage');
    const ipLocale = getCookie('ipLocale')?.toString() || 'default';
    const lst = isLocalStorageAvailable ? localStorage.getItem('lst') : '';
    const lstTO = (isLocalStorageAvailable && localStorage.getItem('lst_TO')) || '0';
    const lstTOTimestamp = parseInt(lstTO, 10);

    if (
      (lst !== 'true' || lstTOTimestamp * 1000 < Date.now()) &&
      isValidLocale(ipLocale) &&
      !isMatchLocaleCountry(ipLocale, locale)
    ) {
      const [la, co] = ipLocale.split('_');

      setCookieLanguage(la);
      setCookieCountry(co);

      setOpenedModal('locale-selector');
    }
  }, [locale, setOpenedModal]);

  return (
    <>
      <S.LocaleSelectorButton
        type="button"
        $buttonPosition={buttonPosition}
        onClick={() => {
          setCookieLanguage('');
          setOpenedModal('locale-selector');
          pushToDataLayer(parseLocaleSelectorDataLayer('open'));
        }}
        aria-label="Select language"
        aria-haspopup="true"
        data-cs-capture=""
      >
        {country && <S.Flag $country={country.toLowerCase()} data-testid="locale-selector-flag" />}
        {language && (
          <S.Language component="span" variant="title" testId="locale-selector-language">
            {language}
          </S.Language>
        )}
      </S.LocaleSelectorButton>
      {openedModal === 'locale-selector' && (
        <LocaleSelectorModal
          onClose={() => setOpenedModal(null)}
          country={cookieCountry || country}
          language={cookieLanguage || language}
          title={title || t('title')}
          btnClose={btnClose || t('btnClose')}
          btnContinue={btnContinue || t('btnContinue')}
          cartWarning={cartWarning || t('cartWarning')}
          buttonPosition={buttonPosition}
        />
      )}
    </>
  );
};
