import { useCallback, memo, useRef } from 'react';
import type { ReactElement } from 'react';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import isEqual from 'react-fast-compare';

import { useSearchContext } from '../../../../utilities/context/dynamic/SearchContext';
import { useNavActionContext } from '../../../../utilities/context/dynamic/NavActionContext';
import { useMediaQueryContext } from '../../../../utilities/context/dynamic/MediaQueryContext';
import SearchIcon from '../../core/icons/SearchIcon';
import { colors, media } from '../../core/styles';
import type { UseComponentSizeCallback } from '../../../../utilities/dom';
import { useComponentSize, useWindowSize } from '../../../../utilities/dom';

const S = {
  Toggle: styled.button<{ $isSearchBoxOpen: boolean }>`
    position: relative;
    height: var(--top-nav-height);
    padding: 18px 10px;
    display: flex;
    align-items: center;

    svg {
      width: 14px;
      opacity: ${({ $isSearchBoxOpen }) => ($isSearchBoxOpen ? 0 : 1)};
      transition: opacity 0.1s linear;
      transition-delay: 0.1s;

      @media (prefers-reduced-motion) {
        transition: none;
      }
    }

    label {
      color: ${colors.WHITE};
      margin-left: 10px;
      cursor: pointer;
      text-transform: lowercase;

      @media ${media.lessThan('sm')} {
        display: none;
      }
    }
  `,
};

interface ToggleSearchV2Props {
  placeholder?: string;
}

const ToggleSearchV2Component = ({ placeholder }: ToggleSearchV2Props): ReactElement => {
  const ref = useRef<HTMLButtonElement>(null);

  const { isSearchBoxOpen, setIsSearchBoxOpen, setSearchToggleSize } = useSearchContext();
  const { sideNavOpened, setSideNavOpened } = useNavActionContext();
  const { isLargeDesktop } = useMediaQueryContext();

  const { t } = useTranslation('common', { keyPrefix: 'search.suggestions' });

  const { width } = useWindowSize({ debounceTime: 150 });

  // save toggle size/position so we can transition to it when opening/closing the search box
  useComponentSize(ref.current, {
    debounceTime: 150,
    callback: useCallback<UseComponentSizeCallback>(
      ([, , defaultCallback]) => defaultCallback,
      // update component size/position when window width changes
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [width]
    ),
    onResize: setSearchToggleSize,
  });

  const openSearchBox = useCallback(() => {
    if (!isSearchBoxOpen) {
      document.body.classList.add('no-scroll');

      if (sideNavOpened && !isLargeDesktop) {
        setSideNavOpened(false);
      }

      setIsSearchBoxOpen(true);
    }
  }, [isSearchBoxOpen, sideNavOpened, isLargeDesktop, setIsSearchBoxOpen, setSideNavOpened]);

  return (
    <S.Toggle
      ref={ref}
      onClick={openSearchBox}
      className="search-toggle"
      data-testid="search-toggle"
      aria-label={placeholder || t('searchbar.placeholder')}
      $isSearchBoxOpen={isSearchBoxOpen}
    >
      <SearchIcon color={colors.WHITE} />
      <label htmlFor="search-input">{placeholder || t('searchbar.placeholder')}</label>
    </S.Toggle>
  );
};

export const ToggleSearchV2 = memo(ToggleSearchV2Component, isEqual);
