import type { ReactElement } from 'react';
import Script from 'next/script';
import { parseLocale } from '../../../utilities/parsers';

/**
 * each locale has it's own key
 */
const usabillaSources: Record<string, string> = {
  de: '525643ea8efd',
  en: 'f39bdf633ac9',
  es: 'e97da67879cc',
  fr: '080dc11cfe69',
  it: 'c14cb9e3de85',
  nl: '5854447adcad',
  zh: '27174a4c52c8',
  ja: '6297cd62f374',
};

interface UsabillaProps {
  locale?: string;
}

export const Usabilla = ({ locale }: UsabillaProps): ReactElement => {
  const { language } = parseLocale(locale || 'nl_nl');
  const usabillaSource = usabillaSources[language];

  return usabillaSource ? (
    <Script id="usabilla-script-init" strategy="afterInteractive" async defer>
      {`window.lightningjs||function(e){function n(n,i){return i&&(i+=(/\\?/.test(i)?"&":"?")+"lv=1"),e[n]||function(){var a=window,r=document,o=n,d=r.location.protocol,s="load",l=0;!function(){function n(){u.P(s),u.w=1,e[o]("_load")}e[o]=function(){function n(){return n.id=i,e[o].apply(n,arguments)}var t,i=++l;return t=this&&this!=a&&this.id||0,(u.s=u.s||[]).push([i,t,arguments]),n.then=function(e,t,a){var r=u.fh[i]=u.fh[i]||[],o=u.eh[i]=u.eh[i]||[],d=u.ph[i]=u.ph[i]||[];return e&&r.push(e),t&&o.push(t),a&&d.push(a),n},n};var u=e[o]._={};u.fh={},u.eh={},u.ph={},u.l=i?i.replace(/^\\/\\//,("https:"==d?d:"http:")+"//"):i,u.p={0:+new Date},u.P=function(e){u.p[e]=new Date-u.p[0]},u.w&&n(),a.addEventListener?a.addEventListener(s,n,!1):a.attachEvent("on"+s,n);var c=function(){function e(){return["<head></head><",n,' onload="var d=',f,";d.getElementsByTagName('head')[0].",d,"(d.",s,"('script')).",l,"='",u.l,"'\\"></",n,">"].join("")}var n="body",i=r[n];if(!i)return setTimeout(c,100);u.P(1);var a,d="appendChild",s="createElement",l="src",h=r[s]("div"),p=h[d](r[s]("div")),v=r[s]("iframe"),f="document";h.style.display="none",i.insertBefore(h,i.firstChild).id=t+"-"+o,v.frameBorder="0",v.id=t+"-frame-"+o,/MSIE[ ]+6/.test(navigator.userAgent)&&(v[l]="javascript:false"),v.allowTransparency="true",p[d](v);try{v.contentWindow[f].open()}catch(e){u.domain=r.domain,a="javascript:var d="+f+".open();d.domain='"+r.domain+"';",v[l]=a+"void(0);"}try{var w=v.contentWindow[f];w.write(e()),w.close()}catch(n){v[l]=a+'d.write("'+e().replace(/"/g,String.fromCharCode(92)+'"')+'");d.close();'}u.P(2)};u.l&&setTimeout(c,0)}()}(),e[n].lv="1",e[n]}var t="lightningjs",i=window[t]=n(t);i.require=n,i.modules=e}({}),window.usabilla_live=lightningjs.require("usabilla_live","//w.usabilla.com/${usabillaSource}.js");`}
    </Script>
  ) : (
    <></>
  );
};
