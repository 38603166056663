import type { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import type { NavigationItem } from '../../../../utilities/graphql/codegen';
import { CustomerType } from '../../../../utilities/graphql/codegen';
import { media, colors } from '../../core/styles';
import { getClassNames, getStyleObject, storageAvailable } from '../../../../utilities/helpers';
import { useUserContext } from '../../../../utilities/context/dynamic/UserContext';
import {
  CART_ENTRIES,
  LOGGED_IN_STATUS,
  RECENTLY_VIEWED_PRODUCTS,
} from '../../../../utilities/constants/localStorageKeys';

export interface SideNavItemProps extends NavigationItem {
  deepLevel: number;
  activeIndexArray?: string[];
  activeItemUrl?: string | null;
  currentIndex: string;
  parentActive?: boolean;
  updateActiveItem: (arr: string[]) => void;
}

const style = {
  CloseButton: css`
    position: absolute;
    top: 50%;
    content: ' ';
    right: 20px;
    margin-top: -1px;
    width: 10px;
    height: 2px;
    background-color: ${colors.ACCESSIBILITY_GREY};
  `,
  ItemWrapper: css`
    transform: translateZ(0);
    border-bottom: 1px solid ${colors.BORDER_GREY};
    box-sizing: border-box;
    display: block;
  `,
};

const S = {
  SideNavItem: styled.div<{ $deepLevel: number; $isShown: boolean }>`
    ${({ $deepLevel }) => $deepLevel === 0 && style.ItemWrapper};

    height: ${({ $isShown }) => ($isShown ? 'max-content' : '0px')};
    visibility: ${({ $isShown }) => ($isShown ? 'inherit' : 'hidden')};

    & .sideNav-link--sale,
    .sideNav-link--sale:visited,
    .sideNav-link--sale:hover,
    .sideNav-link--sale:focus {
      color: red;
    }

    & .sideNav-link--highlight {
      font-weight: 700;
    }

    & .sideNav-link--newArrivals,
    .sideNav-link--newArrivals:visited,
    .sideNav-link--newArrivals:hover,
    .sideNav-link--newArrivals:focus {
      color: ${colors.NAVITEM_NEW_ARRIVALS};
    }

    & .sideNav-link--marginTop {
      margin-top: 15px;
    }

    @media ${media.lessThan('sm')} {
      width: 100%;

      & .sideNav-link {
        max-width: 200px;
        word-wrap: break-word;
      }
    }
  `,

  Title: styled.button<{ $deepLevel: number; $isActive: boolean }>`
    border: 0;
    display: block;
    position: relative;
    cursor: pointer;
    font-size: ${({ $deepLevel }) => ($deepLevel === 0 ? '14px' : '13px')};
    font-weight: ${({ $deepLevel }) => ($deepLevel === 0 ? 700 : 'normal')};
    line-height: ${({ $deepLevel }) => ($deepLevel === 0 ? '20px' : '1.4')};
    padding: ${({ $deepLevel }) => ($deepLevel === 0 ? '20px 16px' : '5px 5px 5px 16px')};
    text-transform: ${({ $deepLevel }) => ($deepLevel === 0 ? 'uppercase' : 'capitalize')};
    color: ${({ $deepLevel, $isActive }) =>
      $deepLevel === 0 || $isActive ? colors.NERO_GREY : colors.ACCESSIBILITY_GREY};
    text-align: start;
    width: 100%;

    &::before {
      ${style.CloseButton}
      transform: rotate(${({ $isActive }) => ($isActive ? '0' : '90deg')});
      transition: transform ${({ $deepLevel }) => ($deepLevel === 0 ? '0.5s' : '0.25s')} ease-in-out;

      @media (prefers-reduced-motion) {
        transition: none;
      }
    }

    &::after {
      ${style.CloseButton}
    }

    &:hover,
    &:focus {
      ${({ $deepLevel }) => $deepLevel > 0 && `color: ${colors.NERO_GREY}`};
    }

    @media ${media.lessThan('sm')} {
      width: 299px;
      font-size: 16px;
      padding: ${({ $deepLevel }) => ($deepLevel === 0 ? '18px 40px' : '14px 5px 14px 40px')};
      margin-right: 15px;
    }
  `,

  SubMenuAnimation: styled.div<{ $isActive: boolean }>`
    box-sizing: border-box;
    overflow: hidden;
    max-height: ${({ $isActive }) => ($isActive ? '5000px' : 0)};
    transition: max-height 0.5s
      ${({ $isActive }) => ($isActive ? 'ease-in-out' : 'cubic-bezier(0, 1, 0, 1)')};

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,

  SubMenuWrapper: styled.div<{ $deepLevel: number; $branchChildCount: number }>`
    ${({ $deepLevel }) =>
      $deepLevel === 0 ? 'padding-bottom: 20px' : 'padding: 0px 0px 12px 20px'};

    & > div.sideNav__branch:nth-child(${({ $branchChildCount }) => $branchChildCount}) {
      padding-bottom: 15px;
    }
  `,

  SideNavLink: styled.a<{ $isHighlighted: boolean }>`
    width: fit-content;
    max-width: 194px;
    font-size: 13px;
    display: block;
    position: relative;
    line-height: 1em;
    padding: 9px 5px 9px 16px;
    text-decoration: none;
    text-transform: capitalize;
    color: ${({ $isHighlighted }) =>
      $isHighlighted ? colors.NERO_GREY : colors.ACCESSIBILITY_GREY};
    font-weight: ${({ $isHighlighted }) => ($isHighlighted ? 'bold' : 'normal')};

    &:hover,
    &:focus {
      color: ${colors.NERO_GREY};
    }

    ${({ $isHighlighted }) =>
      $isHighlighted
        ? css`
            &::after {
              border-bottom: 2px solid ${colors.NERO_GREY};
              color: transparent;
              content: '';
              display: block;
              width: calc(100% - 21px);
              left: 16px;
              padding-bottom: 4px;
              pointer-events: none;
              position: absolute;
              text-transform: capitalize;

              @media ${media.lessThan('sm')} {
                left: 40px;
                width: calc(100% - 45px);
              }
            }
          `
        : ''}

    @media ${media.lessThan('sm')} {
      padding: 14px 5px 14px 40px;
      font-size: 16px;
    }
  `,
};

export const SideNavItem = ({
  label,
  // labelAria,
  hideForNonLoggedInVisitors,
  // highlightWhenDuplicates,
  styleModifier,
  // target,
  url,
  children,
  deepLevel,
  activeIndexArray,
  activeItemUrl,
  currentIndex,
  parentActive = true,
  updateActiveItem,
}: SideNavItemProps): ReactElement => {
  const branchChildCount = children?.filter(child => !child?.url).length ?? 0;

  const styleObject = getStyleObject(styleModifier ?? '');
  const classNames = getClassNames(styleModifier ?? '');
  const { user } = useUserContext();

  const isActive = `${activeIndexArray?.slice(0, deepLevel + 1)}` === currentIndex;

  /**
   * fixme: is dirty hack as solution for https://g-star.atlassian.net/browse/FS-1670
   *
   * At logout action, firstly the below code will clear the items from localStorage
   * items: {countryCode}_rvp, loggedInStatus and cartEntries_{countryCode} for all regions
   *
   */
  const cleanOnLogout = () => {
    if (storageAvailable('localStorage')) {
      Object.keys(localStorage)
        .filter(
          (key: string) =>
            key.startsWith(CART_ENTRIES) ||
            key.endsWith(RECENTLY_VIEWED_PRODUCTS) ||
            key === LOGGED_IN_STATUS
        )
        .forEach((key: string) => localStorage.removeItem(key));
    }
  };

  return label ? (
    <S.SideNavItem
      $deepLevel={deepLevel}
      className={url ? 'sideNav__leaf' : 'sideNav__branch'}
      data-testid={activeItemUrl === url ? 'navigation-node-active' : undefined}
      $isShown={!hideForNonLoggedInVisitors || user?.customerType === CustomerType.Registered}
    >
      {url ? (
        <S.SideNavLink
          href={url}
          onClick={() => url === '/logout' && cleanOnLogout()}
          $isHighlighted={activeItemUrl === url}
          dangerouslySetInnerHTML={{ __html: label }}
          data-title={label ?? ''}
          style={styleObject}
          className={classNames}
          aria-disabled={!parentActive}
          tabIndex={!parentActive ? -1 : undefined}
        />
      ) : (
        <S.Title
          onClick={() => {
            if (activeIndexArray) {
              const currentIndexToArr = currentIndex.split(',');

              if (
                `${activeIndexArray.slice(0, currentIndexToArr.length)}` === `${currentIndexToArr}`
              ) {
                const updatedArray = activeIndexArray;

                updatedArray[currentIndexToArr.length - 1] = '-1';
                updateActiveItem([...updatedArray]);
              } else {
                updateActiveItem([...currentIndexToArr]);
              }
            }
          }}
          $isActive={isActive}
          $deepLevel={deepLevel}
          dangerouslySetInnerHTML={{ __html: label }}
          style={styleObject}
          className={classNames}
          aria-expanded={isActive}
          aria-disabled={!parentActive}
          tabIndex={!parentActive ? -1 : undefined}
        />
      )}
      {children && children.length > 0 && (
        <S.SubMenuAnimation $isActive={isActive}>
          <S.SubMenuWrapper $deepLevel={deepLevel} $branchChildCount={branchChildCount}>
            {children?.map((subMenu, index) => (
              <SideNavItem
                key={index}
                {...subMenu}
                deepLevel={deepLevel + 1}
                currentIndex={`${currentIndex},${index}`}
                activeIndexArray={activeIndexArray}
                updateActiveItem={updateActiveItem}
                activeItemUrl={activeItemUrl}
                parentActive={isActive}
              />
            ))}
          </S.SubMenuWrapper>
        </S.SubMenuAnimation>
      )}
    </S.SideNavItem>
  ) : (
    <></>
  );
};
