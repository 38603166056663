import styled from 'styled-components';
import { Button } from '../../shared/core/button/Button';
import { colors } from '../../shared/core/styles';

export const S = {
  MessageWrapper: styled.div`
    background-color: ${colors.BORDER_GREY};
    border: 2px solid ${colors.PRUSSIAN_BLUE};
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    position: relative;
    z-index: 1;
  `,

  Message: styled.p`
    margin: 0;
    font-weight: 700;
    line-height: 20px;
    padding: 10px;
    text-wrap: pretty;
  `,

  CloseButton: styled(Button)`
    min-width: 40px;
    height: 40px;
    padding: 0;

    &.button--transparent {
      border: 0;

      g {
        fill: ${colors.ACCESSIBILITY_GREY};
      }
    }

    span {
      margin: 0;
    }
  `,
};
