import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import { useNavActionContext } from '../../../../utilities/context/dynamic/NavActionContext';
import { getCategoryPathsFromPDPUrl } from '../../../../utilities/ssr';
import { colors, media } from '../../core/styles';
import { Typography } from '../../core/typography/Typography';
import { usePDPContext } from '../../../../utilities/context/static/PDPContext';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';

const S = {
  Wrapper: styled.div<{ $isShown: boolean }>`
    position: absolute;
    width: 100vw;
    left: 0;
    right: 0;
    background: ${colors.WHITE};
    transition: max-height 0.4s ease-in-out;
    overflow: hidden;
    box-shadow: 0 0 90px rgb(0 0 0 / 20%);
    box-sizing: border-box;
    display: none;

    @media ${media.greaterThan('lg')} {
      max-height: ${({ $isShown }) => ($isShown ? '500px' : 0)};
      display: block;
    }

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,

  Container: styled.div<{ $index: number }>`
    display: flex;
    width: 400%;
    justify-content: flex-start;
    transition: transform 0.4s;
    transform: ${({ $index }) => `translateX(-${$index * 100}vw)`};
    overflow: hidden;

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,

  FlyoutRow: styled.div`
    padding: 20px 46px 40px;
    display: flex;
    justify-content: flex-start;
    width: 100vw;
    box-sizing: border-box;
  `,

  FlyoutColumn: styled.ul`
    flex: 0 1 20%;
    list-style: none;
    margin: 0;
    padding: 0;

    &:nth-child(5) {
      border-left: 1px solid ${colors.ACCESSIBILITY_GREY};
      padding-left: 40px;
      flex-basis: calc(20% - 41px);
    }
  `,

  FlyoutItem: styled.li`
    line-height: 20px;
    color: ${colors.ACCESSIBILITY_GREY};
  `,

  Title: styled(Typography)`
    padding: 9px 0;
    margin-bottom: 14px;
    text-transform: uppercase;
    font-size: 1em;
    line-height: 1em;
  `,

  FlyoutLink: styled.a<{ $active?: boolean }>`
    display: inline-block;
    margin-top: 9px;
    margin-bottom: ${({ $active }) => ($active ? '5px' : '9px')};
    line-height: 1em;
    text-decoration: none;
    text-transform: capitalize;
    color: ${colors.ACCESSIBILITY_GREY};

    ${({ $active }) =>
      $active &&
      css`
        font-weight: 700;
        color: ${colors.NERO_GREY};
        border-bottom: 2px solid ${colors.NERO_GREY};
        padding-bottom: 4px;
      `};

    &:hover,
    &:focus {
      color: ${colors.NERO_GREY};
    }
  `,
};

export const FlyoutNavigation = (): ReactElement => {
  const {
    product: { url },
  } = usePDPContext();
  const { flyoutNavigation } = useStaticContext();
  const { superCategory, pageCategory } = getCategoryPathsFromPDPUrl(url);
  const flyouts = flyoutNavigation.navigation?.filter(
    item => item?.label && !item.url && item.children
  );
  const { flyoutItem } = useNavActionContext();
  const { locale } = useAppContext();
  const [position, setPosition] = useState(0);
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const positionIndex = flyouts?.findIndex(
      item => item?.label?.toLowerCase() === flyoutItem.toLowerCase()
    );
    const index = positionIndex === undefined ? -1 : positionIndex;

    setPosition(prev => (index >= 0 ? index : prev));
    setIsShown(index >= 0);
  }, [flyoutItem, flyouts]);

  return (
    <S.Wrapper $isShown={isShown}>
      <S.Container $index={position}>
        {flyouts?.map((flyout, i) => (
          <S.FlyoutRow key={i}>
            {flyout?.children?.map((child, j) => (
              <S.FlyoutColumn key={j}>
                <S.FlyoutItem>
                  <S.Title component="h3" variant="h3" color={colors.NERO_GREY}>
                    {child?.label}
                  </S.Title>
                </S.FlyoutItem>
                {child?.children?.map((grandChild, k) => (
                  <S.FlyoutItem key={k}>
                    <S.FlyoutLink
                      href={`/${locale}${grandChild?.url}`}
                      $active={
                        pageCategory?.toLowerCase() === grandChild?.label?.toLowerCase() &&
                        flyoutItem.toLowerCase() === superCategory?.toLowerCase()
                      }
                    >
                      {grandChild?.label}
                    </S.FlyoutLink>
                  </S.FlyoutItem>
                ))}
              </S.FlyoutColumn>
            ))}
          </S.FlyoutRow>
        ))}
      </S.Container>
    </S.Wrapper>
  );
};
