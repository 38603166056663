import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const SocialFacebookInvertedIcon = ({
  color = colors.BLACK,
  hoverColor,
}: IconProps): ReactElement => (
  <Svg viewBox="0 0 1024 1024" $hoverColor={hoverColor}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill={color}>
        <path d="M512 1024A512 512 0 1 0 512 0a512 512 0 0 0 0 1024Zm67-690h88V224h-88c-74 0-133 65-133 144v55h-89v110h89v266l110 1V533h111V423H556v-56c0-19 12-33 23-33Z" />
      </g>
    </g>
  </Svg>
);

export default SocialFacebookInvertedIcon;
