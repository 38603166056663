import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const SocialInstagramInvertedIcon = ({
  color = colors.BLACK,
  hoverColor,
}: IconProps): ReactElement => (
  <Svg viewBox="0 0 1024 1024" $hoverColor={hoverColor}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill={color}>
        <path d="M512 0a512 512 0 1 0 0 1024A512 512 0 0 0 512 0zm292 633a166 166 0 0 1-47 124 166 166 0 0 1-124 47c-31 2-41 2-121 2s-90 0-121-2a166 166 0 0 1-124-47 166 166 0 0 1-47-124c-2-31-2-41-2-121s0-90 2-121a166 166 0 0 1 47-124 166 166 0 0 1 124-47c31-2 41-2 121-2s90 0 121 2c31 1 53 6 71 13a147 147 0 0 1 86 86c8 19 13 40 14 72 2 31 2 41 2 121s0 90-2 121z" />
        <path d="M751 393c-1-28-6-44-10-54-5-14-12-24-22-34a91 91 0 0 0-34-22c-10-4-26-9-54-10-31-2-41-2-119-2s-88 0-119 2c-29 1-44 6-54 10-14 5-24 11-34 22a91 91 0 0 0-22 34c-4 10-9 26-10 54-2 31-2 40-2 119s0 88 2 119c1 28 6 44 10 54 5 14 11 24 22 34s20 17 34 22c10 4 26 9 54 10 31 2 40 2 119 2s88 0 119-2c28-1 44-6 54-10 14-5 24-11 34-22s17-20 22-34c4-10 9-26 10-54 2-31 2-41 2-119s0-88-2-119zM512 663a151 151 0 1 1 0-302 151 151 0 0 1 0 302zm157-273a35 35 0 1 1 0-70 35 35 0 0 1 0 70z" />
        <path d="M512 414a98 98 0 1 0 0 196 98 98 0 0 0 0-196z" />
      </g>
    </g>
  </Svg>
);

export default SocialInstagramInvertedIcon;
