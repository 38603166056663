import type { ReactElement } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import type { GlobalAccessibilityLabels as GlobalAccessibilityLabelsSchema } from '../../../amplienceTypes/schemas/imported/global-accessibility-labels-schema';
import { useAppContext } from '../../../utilities/context/static/AppContext';

const S = {
  Label: styled.a`
    position: absolute;
    left: -999px;
    top: -999px;
  `,
};

export const AccessibilityLabels = ({
  accessibilityLabel1,
  accessibilityLabel2,
}: GlobalAccessibilityLabelsSchema): ReactElement => {
  const { locale } = useAppContext();

  const { t } = useTranslation('common', { keyPrefix: 'accessibilityLabels' });

  return (
    <>
      <S.Label href={`/${locale}/customer-service/contact`} tabIndex={0}>
        {accessibilityLabel1 || t('accessibilityLabel1')}
      </S.Label>
      <S.Label href="#container">{accessibilityLabel2 || t('accessibilityLabel2')}</S.Label>
    </>
  );
};
