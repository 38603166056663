import type { ReactElement } from 'react';
import { memo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { getCookie } from 'cookies-next';
import { colors } from '../../core/styles';
import LoginIcon from '../../core/icons/LoginIcon';
import type { Navigation } from '../../../../utilities/graphql/codegen';
import {
  CustomerType,
  NavigationType,
  NavigationTypeParam,
} from '../../../../utilities/graphql/codegen';
import { fetchNavigation } from '../../../../utilities/graphql/queries';
import { Typography } from '../../core/typography/Typography';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import { useUserContext } from '../../../../utilities/context/dynamic/UserContext';
import { useNavActionContext } from '../../../../utilities/context/dynamic/NavActionContext';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';

interface LoginButtonProps {
  loginLabel?: string;
  myAccountLabel?: string;
}

const S = {
  LoginButton: styled.a`
    display: flex;
    gap: 4px;
    align-items: center;
    height: 54px;
    line-height: 54px;
    text-decoration: none;

    &:hover,
    &:focus {
      span {
        border-color: ${colors.WHITE};
      }
    }
  `,

  IconWrapper: styled.span`
    display: inline-block;
    min-width: 16px;
    max-width: 16px;
    margin-right: 7px;
    height: 100%;
    margin-top: -2px;
  `,

  Title: styled.span`
    color: ${colors.WHITE};
    border-bottom: 2px solid transparent;
    max-width: 115px;
    line-height: normal;
    font-size: 14px;
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `,

  ProfileContainer: styled.div<{ $show: boolean }>`
    position: absolute;
    top: 54px;
    right: 0;
    width: 246px;
    background: ${colors.WHITE};
    box-shadow: 0 0 18px 0 rgb(0 0 0 / 20%);
    padding: 20px 46px 40px;
    text-align: left;
    visibility: ${({ $show }) => ($show ? 'visible' : 'hidden')};
    transform: ${({ $show }) => ($show ? 'translateY(0)' : 'translateY(-600px)')};
    transition: transform 0.2s linear, visibility 0s linear 0.2s;
    z-index: -1;

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,

  AccountTitle: styled(Typography)`
    line-height: 20px;
    padding: 20px 16px;
    text-transform: uppercase;
    display: block;
  `,

  AccountLink: styled.a`
    line-height: 1em;
    text-transform: capitalize;
    color: ${colors.ACCESSIBILITY_GREY};
    display: block;
    padding: 9px 5px 9px 16px;
    text-decoration: none;

    &:hover,
    &:focus {
      color: ${colors.NERO_GREY};
    }
  `,
};

const LoginButtonComponent = ({ loginLabel, myAccountLabel }: LoginButtonProps): ReactElement => {
  const [accountDropdownData, setAccountDropdownData] = useState<Navigation>({});
  const [firstNameFromCookie, setFirstNameFromCookie] = useState<string>();

  const { locale } = useAppContext();
  const {
    configuration: { navigationType },
  } = useStaticContext();
  const { flyoutItem, setFlyoutItem } = useNavActionContext();
  const { user } = useUserContext();

  useEffect(() => {
    const firstName = getCookie('firstName')?.toString();

    if (firstName) {
      setFirstNameFromCookie(firstName);
    }
  }, []);

  const setMyAccount = () => {
    if (navigationType === NavigationType.HorizontalMainNavigationFlyOut) {
      setFlyoutItem(NavigationTypeParam.FlyoutMyAccountNavigation);
    }
  };

  useEffect(() => {
    if (navigationType === NavigationType.HorizontalMainNavigationFlyOut) {
      const getMyAccountNavigationData = async () => {
        const { navigation } = await fetchNavigation(
          locale,
          NavigationTypeParam.FlyoutMyAccountNavigation
        );

        setAccountDropdownData(navigation ?? {});
      };

      getMyAccountNavigationData();
    }
  }, [locale, navigationType]);

  return (
    <>
      <S.LoginButton
        href={
          user?.customerType === CustomerType.Registered
            ? `/${locale}/account`
            : `/${locale}/account/create`
        }
        data-testid="login-button"
        onMouseEnter={setMyAccount}
        onMouseLeave={() => setFlyoutItem('')}
        data-cs-capture=""
      >
        <S.IconWrapper>
          <LoginIcon color={colors.WHITE} />
        </S.IconWrapper>
        <S.Title>
          {(() => {
            if (!user) {
              return firstNameFromCookie;
            }

            if (user.customerType === CustomerType.Registered) {
              return user.firstName || user.name;
            }

            return loginLabel;
          })()}
        </S.Title>
      </S.LoginButton>
      {flyoutItem === NavigationTypeParam.FlyoutMyAccountNavigation && (
        <S.ProfileContainer
          $show={!!accountDropdownData}
          onMouseEnter={setMyAccount}
          onMouseLeave={() => setFlyoutItem('')}
        >
          <S.AccountTitle component="span" variant="title">
            {myAccountLabel}
          </S.AccountTitle>
          {user?.customerType === CustomerType.Registered &&
            navigationType === NavigationType.HorizontalMainNavigationFlyOut &&
            accountDropdownData.navigation?.map((dropdownItem, i) => {
              if (dropdownItem?.children) {
                return dropdownItem.children.map((item, j) =>
                  item?.url && item?.label ? (
                    <S.AccountLink href={`/${locale}${item.url}`} key={j}>
                      {item.label}
                    </S.AccountLink>
                  ) : (
                    <></>
                  )
                );
              }

              return dropdownItem?.url && dropdownItem?.label ? (
                <S.AccountLink href={`/${locale}${dropdownItem.url}`} key={i}>
                  {dropdownItem.label}
                </S.AccountLink>
              ) : (
                <></>
              );
            })}
        </S.ProfileContainer>
      )}
    </>
  );
};

export const LoginButton = memo(LoginButtonComponent);
