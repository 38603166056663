import { useRouter } from 'next/router';
import type { ReactElement } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useAppContext } from '../../../utilities/context/static/AppContext';
import { useCartContext } from '../../../utilities/context/dynamic/CartContext';
import { useApplyVoucherMutation } from '../../../utilities/graphql/codegen';
import { fetchCartData } from '../../../utilities/graphql/queries';
import { S } from './styles';
import { useStaticContext } from '../../../utilities/context/static/StaticContext';

export const AppliedVoucherNotification = (): ReactElement => {
  const { notifications: { voucher: { saved = '', applied = '' } = {} } = {} } = useStaticContext();
  const router = useRouter();
  const { locale } = useAppContext();
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const { setMiniCart, setCartHovered } = useCartContext();
  const [applyVoucherMutation] = useApplyVoucherMutation();

  const removeUrlParam = useCallback(() => {
    const url = new URL(window.location.href);

    url.searchParams.delete('vouchercode');
    router.replace(url, url, { shallow: true });
  }, [router]);

  useEffect(() => {
    const { vouchercode } = router.query;
    const voucher = Array.isArray(vouchercode) ? vouchercode.at(0) : vouchercode;

    if (!voucher) {
      return;
    }

    (async () => {
      const { data } = await applyVoucherMutation({
        variables: {
          voucher,
          locale,
        },
      });

      if (!data?.applyVoucher) {
        return;
      }

      const { cart, errors } = data.applyVoucher;

      // presence of absence of the properties acts like a "feature flag"
      const voucherApplied = !!cart && !errors;
      const voucherSaved = !cart && !errors;
      const voucherError = !cart && !!errors;

      switch (true) {
        case voucherApplied: {
          // voucher API cart data is broken so we need to refetch the cart manually after voucher was applied
          const { cart: updatedCart } = await fetchCartData(locale);

          if (updatedCart) {
            setMiniCart(updatedCart);
            setMessage(applied);
            setShowMessage(true);
          }

          break;
        }
        case voucherSaved: {
          setMessage(saved);
          setShowMessage(true);

          break;
        }
        case voucherError: {
          setMessage(errors?.at(0)?.message as string);
          setShowMessage(true);

          break;
        }
        default: {
          // no action
        }
      }
    })();
  }, [applyVoucherMutation, locale, setMiniCart, router, setCartHovered, saved, applied]);

  return showMessage && message ? (
    <S.MessageWrapper>
      <S.Message data-testid="global-notification-voucher">{message}</S.Message>

      <S.CloseButton
        onClick={() => {
          removeUrlParam();
          setShowMessage(false);
        }}
        icon="close"
        ordinal="transparent"
      />
    </S.MessageWrapper>
  ) : (
    <></>
  );
};
