import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

export const InfoCircle = ({ color = colors.BLACK, hoverColor }: IconProps): ReactElement => (
  <Svg
    version="1.1"
    baseProfile="tiny"
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    overflow="inherit"
    viewBox="0 0 1024 1024"
    width="100%"
    height="100%"
    $hoverColor={hoverColor}
  >
    <g fill={color}>
      <path d="M512,0C230,0,0,229.552,0,511.002C0,793.449,230,1024,512,1024c283,0,512-230.551,512-512.998C1024,229.552,795,0,512,0zM576,783.47H448V448.125h20h108V783.47z M512,400.219c-36,0-64-27.945-64-62.877c0-35.931,28-64.874,64-64.874c35,0,64,28.943,64,64.874C576,372.273,547,400.219,512,400.219z" />
    </g>
  </Svg>
);
