import { useTranslation } from 'next-i18next';
import { AutoFocusInside } from 'react-focus-lock';
import { useMemo } from 'react';
import { S } from './styles';
import type { CookieWallV3 } from '../../../amplienceTypes/schemas/imported/cookie-wall-v3-schema';
import type { Ordinal } from '../../../amplienceTypes/partials/imported/pdp-partials';

type CookieWallElementProps = {
  changeSettingsHandler: () => void;
  refuseOptionalHandler: () => void;
  acceptAllHandler: () => void;
};

const CookieWallV3Element = ({
  changeSettingsHandler,
  refuseOptionalHandler,
  acceptAllHandler,
  content,
  acceptButton,
  rejectButton,
  preferencesButton,
}: CookieWallElementProps & Partial<CookieWallV3>) => {
  const { t } = useTranslation('common', { keyPrefix: 'globalCookieWallSettings' });

  const defaultPreferencesButtonAlign = useMemo(
    () => (acceptButton?.align || rejectButton?.align ? '' : 'right'),
    [acceptButton?.align, rejectButton?.align]
  );

  const handleButtonType = (type?: unknown) => (type === 'primary' ? 'success' : (type as Ordinal));

  return (
    <>
      <S.CookieContent component="div" variant="body">
        <S.Text dangerouslySetInnerHTML={{ __html: content?.html ?? '' }} />
      </S.CookieContent>

      <S.ButtonsV3>
        <S.CookieV3ButtonWrapper
          $align={(preferencesButton?.align as unknown as string) ?? defaultPreferencesButtonAlign}
          $order={preferencesButton?.order as unknown as string}
          $mobileOrder={preferencesButton?.mobile?.order as unknown as string}
        >
          <S.CookieButton
            label={((preferencesButton?.text as unknown) || t('preferencesBtn')) as string}
            onClick={changeSettingsHandler}
            ordinal={handleButtonType(preferencesButton?.type)}
            testId="cookie-wall-change-settings"
          />
        </S.CookieV3ButtonWrapper>

        <S.CookieV3ButtonWrapper
          $align={rejectButton?.align as unknown as string}
          $order={rejectButton?.order as unknown as string}
          $mobileOrder={rejectButton?.mobile?.order as unknown as string}
        >
          <S.CookieButton
            label={(rejectButton?.text as unknown as string) || t('refuseOptionalBtn')}
            ordinal={handleButtonType(rejectButton?.type)}
            onClick={refuseOptionalHandler}
            testId="cookie-wall-refuse-optional"
          />
        </S.CookieV3ButtonWrapper>

        <S.CookieV3ButtonWrapper
          $align={acceptButton?.align as unknown as string}
          $order={acceptButton?.order as unknown as string}
          $mobileOrder={acceptButton?.mobile?.order as unknown as string}
        >
          <AutoFocusInside>
            <S.CookieButton
              label={(acceptButton?.text as unknown as string) || t('acceptAllBtn')}
              ordinal={handleButtonType(acceptButton?.type)}
              onClick={acceptAllHandler}
              testId="cookie-wall-accept"
            />
          </AutoFocusInside>
        </S.CookieV3ButtonWrapper>
      </S.ButtonsV3>
    </>
  );
};

export default CookieWallV3Element;
