import { useCallback, useEffect, useState } from 'react';
import type { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import type { Title as USPItemSchema } from '../../../amplienceTypes/schemas/exported/usp-item-schema';
import type {
  TimeZone,
  Title as USPCountdownSchema,
} from '../../../amplienceTypes/schemas/exported/usp-countdown-schema';
import type {
  Title as UspList,
  ColorScheme,
} from '../../../amplienceTypes/schemas/exported/usp-list-schema';
import { useStaticContext } from '../../../utilities/context/static/StaticContext';
import { useLayoutDesignContext } from '../../../utilities/context/dynamic/LayoutDesignContext';
import { useMediaQueryContext } from '../../../utilities/context/dynamic/MediaQueryContext';
import { colors, media } from '../core/styles';
import { USPItemContainer } from './USPItemContainer';
import { USPItem } from './USPItem';
import { USPCountdown } from './USPCountdown';
import {
  USP_ANIMATION_DURATION,
  USP_MESSAGE_DISPLAY_TIME,
  timeOffsets,
} from '../../../utilities/constants/usp';

const fullCycleTime = USP_ANIMATION_DURATION + USP_MESSAGE_DISPLAY_TIME + USP_ANIMATION_DURATION;

const S = {
  Wrapper: styled.div<{ $colorScheme?: ColorScheme; $hasStickyProductImageGallery?: boolean }>`
    ${({ $hasStickyProductImageGallery }) =>
      $hasStickyProductImageGallery &&
      css`
        position: sticky;
        top: var(--top-nav-height);
        z-index: 1;

        @media ${media.lessThan('lg')} {
          z-index: initial;
        }
      `}

    height: var(--usp-bar-height);
    width: 100%;
    text-align: center;
    overflow: hidden;
    display: block;
    background-color: ${colors.WHITE};

    ${({ $colorScheme }) =>
      ($colorScheme === 'Black Friday' &&
        css`
          background-color: ${colors.BLACK};
        `) ||
      ($colorScheme === 'Dark' &&
        css`
          background-color: ${colors.NERO_GREY};
        `)}
  `,

  USPBarList: styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
  `,
};

export const HeaderUSP = ({ usp, colorScheme }: UspList): ReactElement => {
  const {
    configuration: { enableUspScroll },
  } = useStaticContext();
  const { hasStickyProductImageGallery } = useLayoutDesignContext();
  const { isMobile } = useMediaQueryContext();

  const [activeIndex, setActiveIndex] = useState(0);

  const convertStringToDate = useCallback(
    (endDateStr: string, timezone: TimeZone) =>
      new Date(`${endDateStr.replace(' ', 'T')}${timeOffsets[timezone]}`),
    []
  );

  const usps = (usp as (USPCountdownSchema & USPItemSchema)[]).filter(item => {
    if (item.countdown && item.countdown.endDate) {
      const endTime = convertStringToDate(
        item.countdown.endDate,
        item.countdown.timezone || 'UTC+2 (CEST)'
      );
      const regex = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/g;

      if (
        !regex.test(item.countdown.endDate) ||
        Math.floor((endTime.getTime() - Date.now()) / 1000) < 0
      ) {
        return false;
      }

      return item.text && item.text !== '';
    }

    return item.text?.title && item.text?.title !== '';
  });

  useEffect(() => {
    const intervalId =
      enableUspScroll && isMobile && usps.length > 1
        ? setInterval(() => {
            setActiveIndex(activeIndex === usps.length - 1 ? 0 : activeIndex + 1);
          }, fullCycleTime)
        : undefined;

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [activeIndex, enableUspScroll, isMobile, usps.length]);

  return (
    <>
      {usps.length !== 0 && (
        <S.Wrapper
          $colorScheme={colorScheme}
          $hasStickyProductImageGallery={hasStickyProductImageGallery}
          data-qubit-id="usp-bar"
        >
          <S.USPBarList data-testid="usp-list" data-cs-capture="" data-qubit-id="usp-list">
            {usps.map((item, index) => {
              if ('countdown' in item) {
                return (
                  <USPItemContainer
                    suppressHydrationWarning
                    key={index}
                    url={item.link?.url}
                    target={item.link?.target}
                    className={`${
                      enableUspScroll && isMobile && usps.length > 1 ? 'scrollable' : ''
                    } ${
                      index === activeIndex && enableUspScroll && isMobile && usps.length > 1
                        ? 'active'
                        : ''
                    }`}
                  >
                    <USPCountdown
                      endTime={convertStringToDate(
                        item.countdown?.endDate ?? '',
                        item.countdown?.timezone ?? 'UTC+2 (CEST)'
                      )}
                      text={item.text}
                      colorScheme={colorScheme ?? 'Light (default)'}
                    />
                  </USPItemContainer>
                );
              }

              return (
                <USPItemContainer
                  suppressHydrationWarning
                  key={index}
                  url={item.link?.url}
                  target={item.link?.target}
                  className={`${
                    enableUspScroll && isMobile && usps.length > 1 ? 'scrollable' : ''
                  } ${
                    index === activeIndex && enableUspScroll && isMobile && usps.length > 1
                      ? 'active'
                      : ''
                  }`}
                >
                  <USPItem
                    iconName={item.icon}
                    title={item.text?.title}
                    description={item.text?.description}
                    colorScheme={colorScheme ?? 'Light (default)'}
                  />
                </USPItemContainer>
              );
            })}
          </S.USPBarList>
        </S.Wrapper>
      )}
    </>
  );
};
