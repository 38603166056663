import { colors } from '../../styles';

export const smartAppBannerCloseIcon = `
  <svg
    width="14px"
    height="14px"
    viewBox="0 0 14 14"
    version="1.1"
  >
    <title>Close</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-812.000000, -634.000000)" fill=${colors.ACCESSIBILITY_GREY}>
        <g transform="translate(181.000000, 621.000000)">
          <path d="M636.600286,19.6185195 L631,14.0182338 L632.018234,13 L637.618519,18.6002857 L643.218805,13 L644.237039,14.0182338 L638.636753,19.6185195 L644.237039,25.2188052 L643.218805,26.2370389 L637.618519,20.6367532 L632.018234,26.2370389 L631,25.2188052 L636.600286,19.6185195 Z" />
        </g>
      </g>
    </g>
  </svg>
`;
