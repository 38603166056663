import type { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import type { ColorScheme } from '../../../amplienceTypes/schemas/exported/usp-list-schema';
import { colors } from '../core/styles';
import type { Icon } from '../../../amplienceTypes/schemas/exported/usp-item-schema';

const getUSPItemIcon = (icon: Icon) => {
  const uspItemIcons: { [k in Icon]: string } = {
    'Checkbox (default)': '\\f13c',
    Delivery: '\\f1bd',
    Ordering: '\\f1bf',
    Payment: '\\f1c0',
    'Bike Courier': '\\f145',
    'Shopping Cart': '\\f13b',
    'Shopping Bag': '\\f245',
    'Redeem Voucher': '\\f183',
    'G-RAW Logo': '\\f152',
    'No Icon': '',
  };

  return uspItemIcons[icon];
};

const S = {
  ItemIcon: styled.span<{ $iconName: Icon; $colorScheme: ColorScheme }>`
    color: ${colors.NERO_GREY};
    ${({ $colorScheme }) =>
      ($colorScheme === 'Black Friday' || $colorScheme === 'Dark') &&
      css`
        color: ${colors.WHITE};
      `};
    line-height: 16px;
    margin-top: -3px;

    &::before {
      font-family: gstar-icons;
      margin-right: 0.2em;
      text-align: center;
      vertical-align: middle;
      margin-left: 0.2em;
      content: '${({ $iconName }) => getUSPItemIcon($iconName)}';
    }
  `,

  ItemTitle: styled.strong<{ $colorScheme: ColorScheme }>`
    font-weight: 700;
    padding: 0 0.2rem;
    color: ${colors.NERO_GREY};
    ${({ $colorScheme }) =>
      ($colorScheme === 'Black Friday' &&
        css`
          color: ${colors.WHITE};
        `) ||
      ($colorScheme === 'Dark' &&
        css`
          color: ${colors.WHITE_SMOKE_GREY};
        `)}

    & > span {
      color: ${colors.PURE_RED};
    }
  `,
};

export interface USPItemProps {
  iconName?: Icon;
  title?: string;
  description?: string;
  colorScheme: ColorScheme;
}

export const USPItem = ({
  iconName,
  title,
  description,
  colorScheme,
}: USPItemProps): ReactElement => (
  <>
    {iconName && <S.ItemIcon $iconName={iconName} $colorScheme={colorScheme} />}
    <S.ItemTitle
      dangerouslySetInnerHTML={{ __html: title ?? '' }}
      $colorScheme={colorScheme}
      data-qubit-id="usp-title"
    />
    <span data-qubit-id="usp-description">{description}</span>
  </>
);
