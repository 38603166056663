import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import type { ColorScheme } from '../../../amplienceTypes/schemas/exported/usp-list-schema';
import { colors } from '../core/styles';

const S = {
  ItemTitle: styled.strong<{ $colorScheme: ColorScheme }>`
    font-weight: 700;
    line-height: 40px;
    font-size: 13px;
    padding: 0 0.2rem;
    color: ${colors.NERO_GREY};
    ${({ $colorScheme }) =>
      ($colorScheme === 'Black Friday' &&
        css`
          color: ${colors.WHITE};
        `) ||
      ($colorScheme === 'Dark' &&
        css`
          color: ${colors.WHITE_SMOKE_GREY};
        `)}

    & > span {
      color: ${colors.PURE_RED};
    }
  `,
};

export interface USPCountdownProps {
  endTime: Date;
  text?: string;
  colorScheme: ColorScheme;
}

export const USPCountdown = ({ endTime, text, colorScheme }: USPCountdownProps): ReactElement => {
  const [remainingTime, setRemainingTime] = useState('');
  const addZero = (num: number): string => `${num > 9 ? '' : '0'}${num}`;

  useEffect(() => {
    let isMounted = true;
    const calcRemainingTime = () => {
      if (isMounted) {
        const diff = Math.floor((endTime.getTime() - Date.now()) / 1000);
        const second = Math.floor(diff % 60);
        const minute = Math.floor(diff / 60) % 60;
        const hour = Math.floor(diff / 3600) % 24;
        const day = Math.floor(diff / (24 * 3600));

        setRemainingTime(
          text
            ?.replace('%D', `${day}`)
            .replace('%H', `${addZero(hour)}`)
            .replace('%M', `${addZero(minute)}`)
            .replace('%S', `${addZero(second)}`) ?? ''
        );
        setTimeout(calcRemainingTime, 1000);
      }
    };

    calcRemainingTime();

    return () => {
      isMounted = false;
    };
  }, [endTime, text]);

  return (
    <S.ItemTitle
      $colorScheme={colorScheme}
      dangerouslySetInnerHTML={{ __html: remainingTime ?? '' }}
    />
  );
};
