import type { ReactElement } from 'react';
import styled from 'styled-components';
import { NavigationType } from '../../../../utilities/graphql/codegen';
import { media, colors } from '../../core/styles';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { useNavActionContext } from '../../../../utilities/context/dynamic/NavActionContext';

const S = {
  HamburgerButton: styled.button<{ $isFlyout: boolean }>`
    border: 0;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 0;
    height: 54px;
    width: 54px;
    display: inline-block;
    position: relative;

    @media ${media.greaterThan('lg')} {
      display: ${({ $isFlyout }) => ($isFlyout ? 'none' : 'inline-block')};
    }
  `,
  HamburgerIcon: styled.span`
    position: absolute;
    display: inline-block;
    height: 14px;
    left: calc(50% - 9px);
    top: calc(50% - 7px);
    width: 18px;

    @media ${media.greaterThan('sm')} {
      height: 19px;
      width: 24px;
      left: calc(50% - 12px);
      top: calc(50% - 9.5px);
    }
  `,
  Stick: styled.span<{ $opened: boolean }>`
    background-color: ${colors.WHITE};
    display: block;
    height: 2px;
    margin: 4px 0;
    transition: all 0.25s cubic-bezier(0.35, 0, 0.25, 1) 0.3s;
    width: 100%;
    opacity: 1;

    &:first-child {
      margin-top: 0;
      ${({ $opened }) => $opened && `transform: translate(0, 6px) rotate(45deg);`}
    }

    &:nth-child(2) {
      ${({ $opened }) => $opened && `opacity: 0;`}
    }

    &:last-child {
      margin-bottom: 0;
      ${({ $opened }) => $opened && `transform: translate(0, -6px) rotate(-45deg);`}
    }

    @media ${media.greaterThan('sm')} {
      height: 3px;
      margin: 5px 0;

      &:first-child {
        ${({ $opened }) => $opened && `transform: translate(0, 8px) rotate(45deg);`}
      }

      &:last-child {
        ${({ $opened }) => $opened && `transform: translate(0, -8px) rotate(-45deg);`}
      }
    }

    @media ${media.greaterThan('xl')} {
      &:first-child,
      &:last-child {
        transform: none;
      }

      &:nth-child(2) {
        opacity: 1;
      }
    }

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,
};

export const HamburgerButton = (): ReactElement => {
  const { sideNavOpened, toggleSideNav } = useNavActionContext();
  const {
    configuration: { navigationType },
    accessibilityLabels: { openSideBar, closenSideBar } = {},
  } = useStaticContext();

  return (
    <S.HamburgerButton
      $isFlyout={navigationType === NavigationType.HorizontalMainNavigationFlyOut}
      suppressHydrationWarning
      onClick={toggleSideNav}
      data-testid="hamburger-menu"
      aria-label={sideNavOpened ? closenSideBar : openSideBar}
      aria-controls="sideNav"
      id="hamburger-menu"
    >
      <S.HamburgerIcon>
        <S.Stick suppressHydrationWarning $opened={sideNavOpened} />
        <S.Stick suppressHydrationWarning $opened={sideNavOpened} />
        <S.Stick suppressHydrationWarning $opened={sideNavOpened} />
      </S.HamburgerIcon>
    </S.HamburgerButton>
  );
};
