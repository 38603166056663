import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { colors } from '../styles';
import { Svg } from './styles';

const SearchIcon = ({ color = colors.BLACK, hoverColor }: IconProps): ReactElement => (
  <Svg viewBox="0 0 1024 1024" $hoverColor={hoverColor}>
    <path
      fill={color}
      d="M153 458c0-168 137-307 306-307a307 307 0 0 1 0 614 307 307 0 0 1-306-307zm851 425L831 724a459 459 0 1 0-372 194c92 0 178-31 250-78l185 169c24 22 61 20 82-5l33-38c22-24 20-62-5-83z"
    />
  </Svg>
);

export default SearchIcon;
