import { useState, useEffect } from 'react';
import type { PropsWithChildren } from 'react';

export const HideInHighstreetApp = ({ children }: PropsWithChildren) => {
  const [isHighstreet, setIsHighstreet] = useState(false);

  useEffect(() => {
    const isHighstreetUserAgent = window.navigator.userAgent.includes('Highstreet');

    setIsHighstreet(isHighstreetUserAgent);

    if (isHighstreetUserAgent) {
      window.document.documentElement.style.setProperty('--top-nav-height', '0px', 'important');
    }
  }, []);

  return <>{isHighstreet ? null : children}</>;
};
