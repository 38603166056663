import type { ReactElement } from 'react';
import styled from 'styled-components';
import type { GlobalTopNavigation } from '../../../../amplienceTypes/schemas/imported/global-top-navigation-schema';
import { useNavActionContext } from '../../../../utilities/context/dynamic/NavActionContext';
import type { HeaderOpenModalProps } from '../../core/layouts/MasterLayout';
import { media, colors } from '../../core/styles';
import { LocaleSelectorButton } from '../localeSelector/LocaleSelectorButton';
import { Logo } from '../logo/Logo';

export type HeaderItemPosition = 'main-header' | 'side-header';

const S = {
  SideNavHeader: styled.div<{ $sideNavOpened: boolean }>`
    background: ${colors.NERO_GREY};
    border-right: 1px solid ${colors.ACCESSIBILITY_GREY};
    box-sizing: border-box;
    display: block;
    height: 54px;
    left: 0;
    position: absolute;
    top: 0;
    transform: ${({ $sideNavOpened }) =>
      $sideNavOpened ? 'translate3d(0,0,0)' : 'translate(-250px, 0)'};
    transition: transform 0.25s ease-in-out;
    width: 250px;
    z-index: 112;

    @media ${media.greaterThan('sm')} {
      display: none;
    }

    @media ${media.lessThan('sm')} {
      width: 314px;
      transform: ${({ $sideNavOpened }) =>
        $sideNavOpened ? 'translate3d(0,0,0)' : 'translate(-314px, 0)'};
    }

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,
};

export const SideNavHeader = ({
  localeSelector,
  openedModal,
  setOpenedModal,
}: GlobalTopNavigation & HeaderOpenModalProps): ReactElement => {
  const { sideNavOpened } = useNavActionContext();

  return (
    <S.SideNavHeader suppressHydrationWarning $sideNavOpened={sideNavOpened}>
      <Logo logoPosition="side-header" />
      {localeSelector?.content && (
        <LocaleSelectorButton
          buttonPosition="side-header"
          {...localeSelector?.content}
          openedModal={openedModal}
          setOpenedModal={setOpenedModal}
        />
      )}
    </S.SideNavHeader>
  );
};
