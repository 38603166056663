import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { colors } from '../styles';
import { Svg } from './styles';

const ForwardSlashIcon = ({ color = colors.BLACK }: IconProps): ReactElement => (
  <Svg
    version="1.1"
    baseProfile="tiny"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 8 8"
    overflow="inherit"
    xmlSpace="preserve"
  >
    <path d="M5.61538 0H7L2.38462 8H1L5.61538 0Z" fill={color} />
  </Svg>
);

export default ForwardSlashIcon;
