import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { GenericLinkList } from './GenericLinkList';
import { media } from '../../core/styles';
import type { Navigation } from '../../../../utilities/graphql/codegen';
import { NavigationTypeParam } from '../../../../utilities/graphql/codegen';
import { fetchNavigation } from '../../../../utilities/graphql/queries';
import { useAppContext } from '../../../../utilities/context/static/AppContext';

const S = {
  GenericWrapper: styled.div`
    margin: 0 auto;
    max-width: 984px;
  `,

  Wrapper: styled.div`
    margin-top: 30px;
    padding-left: 45px;
    padding-right: 45px;

    @media ${media.greaterThan('sm')} {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 50px;
    }

    @media ${media.greaterThan('lg')} {
      padding-left: 0;
      padding-right: 0;
    }
  `,
};

const GenericLinkPanelComponent = (): ReactElement => {
  const [footerNavigationData, setFooterNavigationData] = useState<Navigation>({});
  const { locale } = useAppContext();

  useEffect(() => {
    const getFooterNavigation = async () => {
      const { navigation } = await fetchNavigation(locale, NavigationTypeParam.FooterNavigation);

      setFooterNavigationData(navigation || {});
    };

    getFooterNavigation();
  }, [locale]);

  return (
    <S.GenericWrapper data-testid="footer-linklist-panel">
      <S.Wrapper>
        {footerNavigationData?.navigation?.map((panelInfo, index) => (
          <GenericLinkList {...panelInfo} key={index} />
        ))}
      </S.Wrapper>
    </S.GenericWrapper>
  );
};

export const GenericLinkPanel = GenericLinkPanelComponent;
