import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const SocialTiktokInvertedIcon = ({
  color = colors.BLACK,
  hoverColor,
}: IconProps): ReactElement => (
  <Svg viewBox="0 0 1024 1024" $hoverColor={hoverColor}>
    <g fill={color} stroke="none">
      <path d="m483 2-16 1a546 546 0 0 0-211 66 509 509 0 0 0-131 109l-10 11a571 571 0 0 0-77 130 511 511 0 0 0 0 386l7 15a544 544 0 0 0 144 188 581 581 0 0 0 175 93 506 506 0 0 0 235 14 499 499 0 0 0 193-75l15-10a546 546 0 0 0 138-147 500 500 0 0 0 64-393l-5-19a567 567 0 0 0-71-149A497 497 0 0 0 807 94l-15-10A509 509 0 0 0 483 2zm165 171c1 26 4 40 14 62 8 18 18 32 33 47a164 164 0 0 0 108 50h9v59l-1 59-20-2a288 288 0 0 1-139-48l-4-2v125c0 134 0 136-5 158a220 220 0 0 1-236 174c-14-1-39-7-48-10a276 276 0 0 1-44-21c-10-6-30-21-39-31l-9-10a221 221 0 0 1-50-191 215 215 0 0 1 102-141c13-8 35-18 45-20a204 204 0 0 1 94-10c2 1 2 5 2 62l-1 60-7-2c-10-2-22-3-32-2-18 2-33 7-45 15-8 5-10 6-18 15a94 94 0 0 0-28 67c0 15 0 17 2 27 3 11 11 28 17 36a100 100 0 0 0 177-34l3-10V412l1-245 59-1h60v7z" />
    </g>
  </Svg>
);

export default SocialTiktokInvertedIcon;
