import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import type { ChangeEvent, KeyboardEvent, MouseEvent, ReactElement } from 'react';
import { memo, useCallback, useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import type { FieldValues, SubmitHandler, UseFormRegister } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { InsightsMethodMap } from 'search-insights';
import styled from 'styled-components';
import fetchService from '../../../../utilities/helpers/fetchService';
import { useNavActionContext } from '../../../../utilities/context/dynamic/NavActionContext';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import { useDataLayerContext } from '../../../../utilities/context/static/DataLayerContext';
import { buildApiUrl, removeLocaleFromFullUrl } from '../../../../utilities/parsers';
import { isSearch } from '../../../../utilities/ssr';
import type { SearchTrackingType } from '../../../../utilities/vendors';
import { parseInternalSearchDataLayer } from '../../../../utilities/vendors';
import { InputField } from '../../core/form';
import { colors, media } from '../../core/styles';
import { useAlgoliaInsights } from '../../vendors/useAlgoliaInsights';
import type { SearchResultPanelProps } from './SearchResultPanel';
import { ToggleSearch } from './ToggleSearch';
import type { SearchResult } from './types';

export interface SearchBoxInputType extends FieldValues {
  keyword: string;
}

interface SearchBoxProps {
  placeholder?: string;
  searchBoxActive: boolean;
  setSearchBoxActive: (value: boolean) => void;
}

const SearchResultPanel = dynamic<SearchResultPanelProps>(
  () => import('./SearchResultPanel').then(mod => mod.SearchResultPanel),
  { ssr: false }
);

const S = {
  Form: styled.form<{ $searchBoxActive: boolean; $sideNavOpened: boolean }>`
    position: absolute;
    right: 0;
    top: 0;
    overflow: visible;
    z-index: -1;
    visibility: ${({ $searchBoxActive }) => ($searchBoxActive ? 'visible' : 'hidden')};
    transform: ${({ $searchBoxActive }) =>
      $searchBoxActive ? `translateY(54px) translateZ(0)` : `translateY(0) translateZ(0)`};
    transition: ${({ $searchBoxActive }) =>
      $searchBoxActive
        ? `transform .15s ease-in,visibility 0s linear 0s`
        : `transform .15s ease-out,visibility 0s linear .15s`};

    @media ${media.greaterThan('xl')} {
      visibility: visible;
      border-left: 1px solid ${colors.ACCESSIBILITY_GREY};
      border-right: 1px solid ${colors.ACCESSIBILITY_GREY};
      z-index: 1;
      width: 170px;
      right: 360px;
      transform: translateY(0) translateZ(0);
      left: auto;
    }

    @media (prefers-reduced-motion) {
      transition: none;
    }

    @media ${media.between('xs', 'xl')} {
      left: ${({ $sideNavOpened }) => ($sideNavOpened ? '314px' : '0')};
    }

    @media ${media.lessThan('xs')} {
      visibility: ${({ $sideNavOpened }) => ($sideNavOpened ? 'hidden' : 'visible')};
    }
  `,

  Input: styled(InputField)<{ $searchBoxActive: boolean }>`
    input {
      background: ${colors.WHITE};
      width: 100%;
      height: 54px;
      border: none;
      padding: 0 42px;
      text-transform: none;
      font-weight: 700;

      @media ${media.greaterThan('xl')} {
        background-color: transparent;
        color: ${({ $searchBoxActive }) => ($searchBoxActive ? colors.BLACK : colors.WHITE)};
        font-size: 14px;
        line-height: 54px;
        padding-right: 15px;
        position: relative;
        z-index: 114;

        &::placeholder {
          font-weight: 700;
          color: ${colors.WHITE};
        }
      }
    }
  `,

  InputAnimation: styled.span<{ $searchBoxActive: boolean }>`
    @media ${media.greaterThan('xl')} {
      background-color: ${colors.WHITE};
      display: block;
      height: 54px;
      position: absolute;
      top: 0;
      left: 0;
      transform-origin: ${({ $searchBoxActive }) => ($searchBoxActive ? `top left` : `top right`)};
      transform: ${({ $searchBoxActive }) => ($searchBoxActive ? `scaleX(1)` : `scaleX(0)`)};
      transition: transform 0.25s cubic-bezier(0.35, 0, 0.25, 1);
      width: 170px;
      z-index: 113;
    }
  `,

  ToggleSearch: styled(ToggleSearch)<{ $searchBoxActive: boolean }>`
    position: absolute;
    left: 0;
    top: 0;
    width: 42px;
    z-index: 1;

    path {
      fill: ${colors.NERO_GREY};
    }

    @media ${media.greaterThan('xl')} {
      display: block;
      height: 100%;
      background: ${({ $searchBoxActive }) =>
        $searchBoxActive ? `transparent` : colors.NERO_GREY};
      z-index: 115;

      span {
        width: 16px;
      }

      path {
        fill: ${({ $searchBoxActive }) => ($searchBoxActive ? colors.NERO_GREY : colors.WHITE)};
      }
    }
  `,

  CloseButton: styled.button`
    position: absolute;
    top: 0;
    right: 0;
    width: 42px;
    height: 54px;
    border: none;
    outline: none;
    cursor: pointer;

    &::before {
      font-family: gstar-icons;
      content: '\\F13F';
      font-size: 16px;
      text-align: left;
    }

    @media ${media.greaterThan('xl')} {
      display: none;
    }
  `,
};

const SearchBoxComponent = ({
  placeholder,
  searchBoxActive = false,
  setSearchBoxActive,
}: SearchBoxProps): ReactElement => {
  const { pushToAlgoliaWithQueryId } = useAlgoliaInsights();
  const [searchResult, setSearchResult] = useState<SearchResult>({});
  const [activeItem, setActiveItem] = useState<number | null>(null);
  const [keyword, setKeyword] = useState('');

  const router = useRouter();

  const { locale } = useAppContext();
  const { sideNavOpened } = useNavActionContext();

  const { t } = useTranslation('common', { keyPrefix: 'globalTopNavigation.searchBox' });

  const { pushToDataLayer } = useDataLayerContext();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SearchBoxInputType>();

  const handleChange = async (evt: ChangeEvent<HTMLInputElement>) => {
    const newKeyword = evt.target.value;

    setKeyword(newKeyword);

    try {
      if (newKeyword) {
        const url = buildApiUrl(`/suggest?search=${newKeyword}&_=${new Date().getTime()}`, locale);
        const response = await fetchService.get<SearchResult>(url);
        const searchData: SearchResult = response.data;

        setSearchResult(searchData);
        setActiveItem(null);
      } else {
        setSearchResult({});
      }
    } catch (error) {
      // TODO Error Handling
    }
  };

  const redirectToSearchResult = useCallback(
    (trackingOption: SearchTrackingType, isItem = true) => {
      try {
        const suggestionCount = searchResult.suggestions?.length || 0;

        if (activeItem === null || !isItem) {
          if (keyword.trim() !== '') {
            window.location.href = `/${locale}/search?q=${keyword}`;
            pushToDataLayer(parseInternalSearchDataLayer(trackingOption, keyword));
            // TODO come back after FES search page is live
            // router.push(`/search?q=${keyword}`);
          }
        } else if (searchResult.suggestions && activeItem < suggestionCount) {
          window.location.href = `/${locale}/search?q=${searchResult.suggestions[activeItem].term}`;
          pushToDataLayer(
            parseInternalSearchDataLayer(
              trackingOption,
              keyword,
              searchResult.suggestions[activeItem].term
            )
          );
          // TODO come back after FES search page is live
          // router.push(`/search?q=${searchResult.suggestions[activeItem].term}`);
        } else if (searchResult.products) {
          const { algoliaObjectId, url } = searchResult.products[activeItem - suggestionCount];
          const productUrl = removeLocaleFromFullUrl(url);
          const event: keyof InsightsMethodMap = searchResult?.algoliaQueryId
            ? 'clickedObjectIDsAfterSearch'
            : 'clickedObjectIDs';
          const eventName = searchResult?.algoliaQueryId
            ? 'SUGGESTION_clickedObjectIDsAfterSearch'
            : 'SUGGESTION_clickedObjectIDs';

          if (searchResult?.algoliaQueryId) {
            localStorage.setItem('algoliaQueryID', searchResult?.algoliaQueryId || '');
          }

          pushToAlgoliaWithQueryId(event, eventName, {
            objectIDs: [algoliaObjectId],
            positions: [activeItem + 1],
          });

          router.push(`/${productUrl}`);
        }
      } catch (e) {
        // TODO Error Handling
      }
    },
    [
      activeItem,
      keyword,
      locale,
      pushToDataLayer,
      router,
      searchResult.products,
      searchResult.suggestions,
      pushToAlgoliaWithQueryId,
      searchResult?.algoliaQueryId,
    ]
  );

  const handleKeydown = (evt: KeyboardEvent<HTMLInputElement>) => {
    try {
      const suggestionCount = searchResult.suggestions?.length || 0;
      const productCount = searchResult.products?.length || 0;

      if (evt.key === 'ArrowUp') {
        setActiveItem(
          activeItem === 0 || !activeItem ? suggestionCount + productCount - 1 : activeItem - 1
        );
      } else if (evt.key === 'ArrowDown') {
        setActiveItem(
          activeItem === suggestionCount + productCount - 1 || activeItem === null
            ? 0
            : activeItem + 1
        );
      } else if (evt.key === 'Enter') {
        evt.preventDefault();
        redirectToSearchResult('enter', activeItem !== null);
      } else if (evt.key === 'Escape') {
        setSearchBoxActive(false);
      }
    } catch (e) {
      // TODO Error Handling
    }
  };

  const subscribe: SubmitHandler<SearchBoxInputType> = values => values;

  useEffect(() => {
    if (isSearch(router.pathname)) {
      const query = (router.query.q || '') as string;

      setKeyword(query);
    }
  }, [router.pathname, router.query.q]);

  return (
    <S.Form
      onSubmit={handleSubmit(subscribe)}
      $searchBoxActive={searchBoxActive}
      suppressHydrationWarning
      $sideNavOpened={sideNavOpened}
      onFocus={() => setSearchBoxActive(true)}
      onBlur={() => setSearchBoxActive(false)}
      tabIndex={-1}
    >
      <S.ToggleSearch
        label="Search"
        type="submit"
        disabled={isSubmitting}
        onClick={(e: MouseEvent) => {
          e.preventDefault();
          redirectToSearchResult('click', false);
        }}
        $searchBoxActive={searchBoxActive}
      />
      <S.Input
        label=""
        placeholder={placeholder || t('placeholder')}
        id="searchBox_input"
        name="keyword"
        register={register as unknown as UseFormRegister<FieldValues>}
        error={errors.keyword && errors.keyword.message}
        onChange={handleChange}
        onKeyDown={handleKeydown}
        $searchBoxActive={searchBoxActive}
        ariaExpanded={searchBoxActive}
        autoComplete="off"
        testId="search-input"
        data-cs-capture=""
        role="combobox"
        value={keyword}
      />
      <S.InputAnimation $searchBoxActive={searchBoxActive} />
      <S.CloseButton disabled={isSubmitting} onClick={() => setSearchBoxActive(false)} />
      {searchBoxActive && (
        <SearchResultPanel
          searchResult={searchResult}
          keyword={keyword}
          searchBoxActive={searchBoxActive}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          redirectToSearchResult={redirectToSearchResult}
        />
      )}
    </S.Form>
  );
};

export const SearchBox = memo(SearchBoxComponent, isEqual);
