import { useCallback, useState } from 'react';
import type { ChangeEvent, KeyboardEvent, ReactElement } from 'react';
import { setCookie } from 'cookies-next';
import { useTranslation } from 'next-i18next';
import FocusLock from 'react-focus-lock';
import CheckIcon from '../core/icons/CheckIcon';
import { S } from './styles';
import type { GlobalCookieWallSettings as GlobalCookieWallSettingsSchema } from '../../../amplienceTypes/schemas/imported/global-cookie-wall-settings-schema';
import { ABDCT, COCO, COCO_COOKIES_EXPIRATION } from '../../../utilities/constants/cookies';
import { useAppContext } from '../../../utilities/context/static/AppContext';
import { CookieWallVersion } from '../../../utilities/graphql/codegen';
import CookieWallElement from './CookieWallElement';
import CookieWallV2Element from './CookieWallV2Element';
import { useStaticContext } from '../../../utilities/context/static/StaticContext';
import { useDataLayerContext } from '../../../utilities/context/static/DataLayerContext';
import { PageTypes } from '../../../utilities/constants';
import CookieWallV3Element from './CookieWallV3Element';

interface CookieWallSettingsProps {
  close: (coco: string) => void;
}

type CookieEvent = 'cookie-accept' | 'cookie-change' | 'cookie-reject';

export const CookieWallSettings = ({
  close,
  title,
  titleV2,
  content,
  contentV2Paragraph1,
  contentV2Paragraph2,
  contentV2Paragraph3,
  affiliates,
  affiliatesLink,
  clickHere,
  clickHereLink,
  ourPartners,
  ourPartnersLink,
  cookieStatement,
  privacyStatement,
  privacyStatementLink,
  changeSettings,
  moreInfo,
  confirmation,
  yesBtn,
  yesToAllBtn,
  preferencesBtn,
  refuseOptionalBtn,
  acceptAllBtn,
  onLabel,
  offLabel,
  necessaryCookiesTitle,
  necessaryCookiesContent,
  analyticsCookiesTitle,
  analyticsCookiesContent,
  marketingRetargetingCookiesTitle,
  marketingRetargetingCookiesContent,
  cookieWallV3,
}: CookieWallSettingsProps & GlobalCookieWallSettingsSchema): ReactElement => {
  const { country } = useAppContext();
  const { pushToDataLayer } = useDataLayerContext();
  const {
    configuration: { cookieWallVersion },
  } = useStaticContext();

  const { t } = useTranslation('common', { keyPrefix: 'globalCookieWallSettings' });

  const [settingsOpen, setSettingsOpen] = useState(false);
  const [cookieSettings, setCookieSettings] = useState({
    analytics: false,
    marketingAndRetargeting: false,
  });
  const enableCookieWallV1 = cookieWallVersion === CookieWallVersion.V1 || !cookieWallVersion;
  const enableCookieWallV2 = cookieWallVersion === CookieWallVersion.V2;
  const enableCookieWallV3 = cookieWallVersion === CookieWallVersion.V3;

  const setCocoCookies = useCallback(
    (all: boolean, cookieEvent: CookieEvent) => () => {
      const cookie = all
        ? '11111'
        : `${cookieSettings.marketingAndRetargeting ? '11' : '00'}${
            cookieSettings.analytics ? '1' : '0'
          }11`;

      setCookie(COCO, cookie, { expires: COCO_COOKIES_EXPIRATION });

      if (all || cookieSettings.analytics) {
        setCookie(ABDCT, false);
      } else {
        setCookie(ABDCT, true);
      }

      pushToDataLayer(
        {
          events: {
            category: 'cookies',
            action: cookieEvent.replace('cookie-', ''),
            label: cookie,
          },
          user: {
            consentLevel: cookie,
          },
          page: {
            countryCode: country.toUpperCase(),
            pageType: window?.AppSettings?.pageType?.toLowerCase() || PageTypes.OTHER,
          },
          event: cookieEvent,
        },
        true
      );

      close(cookie);
    },
    [cookieSettings, pushToDataLayer, close, country]
  );

  const onKeyDownHandler = (event: KeyboardEvent, changedCookieSetting: object) => {
    if (event.key === ' ' || event.keyCode === 32) {
      event.preventDefault();
      setCookieSettings(prev => ({
        ...prev,
        ...changedCookieSetting,
      }));
    }
  };

  const cookieSettingsEl = (
    <>
      <S.CookieTitle component="h1" variant="h3" tabIndex={0}>
        {title || t('title')}
      </S.CookieTitle>
      <S.Subtitle component="h2" variant="h3">
        {necessaryCookiesTitle || t('necessaryCookiesTitle')}
      </S.Subtitle>
      <S.SettingGroup>
        <S.OnOffSwitch>
          <S.CheckMark>
            <CheckIcon />
          </S.CheckMark>
        </S.OnOffSwitch>
        <S.OnOff variant="body">{onLabel || t('onLabel')}</S.OnOff>
        <S.SettingsCookieContent>
          {necessaryCookiesContent || t('necessaryCookiesContent')}
          <br />
          <S.Link href="/cookie-statement">{moreInfo || t('moreInfo')}</S.Link>
        </S.SettingsCookieContent>
      </S.SettingGroup>
      <S.Subtitle component="h2" variant="h3">
        {analyticsCookiesTitle || t('analyticsCookiesTitle')}
      </S.Subtitle>
      <S.SettingGroup>
        <S.OnOffSwitch data-testid="cookie-setting-analytics">
          <S.Switch
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setCookieSettings(prev => ({
                ...prev,
                analytics: e.target.checked,
              }))
            }
            checked={cookieSettings.analytics}
            onKeyDown={(event: KeyboardEvent) =>
              onKeyDownHandler(event, { analytics: !cookieSettings.analytics })
            }
          />
        </S.OnOffSwitch>
        <S.OnOff variant="body">
          {cookieSettings.analytics ? onLabel || t('onLabel') : offLabel || t('offLabel')}
        </S.OnOff>
        <S.SettingsCookieContent>
          {analyticsCookiesContent || t('analyticsCookiesContent')}
          <br />
          <S.Link href="/cookie-statement">{moreInfo || t('moreInfo')}</S.Link>
        </S.SettingsCookieContent>
      </S.SettingGroup>
      <S.Subtitle component="h2" variant="h3">
        {marketingRetargetingCookiesTitle || t('marketingRetargetingCookiesTitle')}
      </S.Subtitle>
      <S.SettingGroup>
        <S.OnOffSwitch data-testid="cookie-setting-marketing">
          <S.Switch
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setCookieSettings(prev => ({ ...prev, marketingAndRetargeting: e.target.checked }))
            }
            checked={cookieSettings.marketingAndRetargeting}
            onKeyDown={(event: KeyboardEvent) =>
              onKeyDownHandler(event, {
                marketingAndRetargeting: !cookieSettings.marketingAndRetargeting,
              })
            }
          />
        </S.OnOffSwitch>
        <S.OnOff variant="body">
          {cookieSettings.marketingAndRetargeting
            ? onLabel || t('onLabel')
            : offLabel || t('offLabel')}
        </S.OnOff>
        <S.SettingsCookieContent>
          {marketingRetargetingCookiesContent || t('marketingRetargetingCookiesContent')}
          <br />
          <S.Link href="/cookie-statement">{moreInfo || t('moreInfo')}</S.Link>
        </S.SettingsCookieContent>
      </S.SettingGroup>
      <S.Buttons>
        <S.YesToAllBtn
          label={yesToAllBtn || t('yesToAllBtn')}
          ordinal="secondary"
          onClick={setCocoCookies(true, 'cookie-change')}
          testId="cookie-wall-accept-all"
        />
        <S.YesBtn
          label={yesBtn || t('yesBtn')}
          ordinal="success"
          onClick={setCocoCookies(false, 'cookie-change')}
          testId="cookie-wall-accept-selected"
        />
      </S.Buttons>
    </>
  );

  const changeSettingsHandler = useCallback(() => setSettingsOpen(true), []);

  return (
    <FocusLock>
      <S.CookieBackdrop />
      <S.CookieWrapper $enableCookieWallV1={enableCookieWallV1}>
        <S.Cookie
          $settingsOpen={settingsOpen}
          $cookieWallVersion={cookieWallVersion}
          data-testid="cookie-wall"
        >
          {settingsOpen ? (
            cookieSettingsEl
          ) : (
            <>
              {enableCookieWallV1 && (
                <CookieWallElement
                  changeSettingsHandler={changeSettingsHandler}
                  yesButtonHandler={setCocoCookies(true, 'cookie-accept')}
                  title={title}
                  content={content}
                  changeSettings={changeSettings}
                  moreInfo={moreInfo}
                  confirmation={confirmation}
                  yesBtn={yesBtn}
                />
              )}
              {enableCookieWallV2 && (
                <CookieWallV2Element
                  changeSettingsHandler={changeSettingsHandler}
                  refuseOptionalHandler={setCocoCookies(false, 'cookie-reject')}
                  acceptAllHandler={setCocoCookies(true, 'cookie-accept')}
                  titleV2={titleV2}
                  contentV2Paragraph1={contentV2Paragraph1}
                  affiliatesLink={affiliatesLink}
                  affiliates={affiliates}
                  clickHereLink={clickHereLink}
                  clickHere={clickHere}
                  contentV2Paragraph2={contentV2Paragraph2}
                  ourPartnersLink={ourPartnersLink}
                  ourPartners={ourPartners}
                  contentV2Paragraph3={contentV2Paragraph3}
                  cookieStatement={cookieStatement}
                  privacyStatementLink={privacyStatementLink}
                  privacyStatement={privacyStatement}
                  preferencesBtn={preferencesBtn}
                  refuseOptionalBtn={refuseOptionalBtn}
                  acceptAllBtn={acceptAllBtn}
                />
              )}
              {enableCookieWallV3 && (
                <CookieWallV3Element
                  changeSettingsHandler={changeSettingsHandler}
                  refuseOptionalHandler={setCocoCookies(false, 'cookie-reject')}
                  acceptAllHandler={setCocoCookies(true, 'cookie-accept')}
                  {...cookieWallV3?.content}
                />
              )}
            </>
          )}
        </S.Cookie>
      </S.CookieWrapper>
    </FocusLock>
  );
};
