// Payment method icon styles
export const paymentIconStyles = [
  { method: 'visa', width: 35, position: '-199px -56px' },
  { method: 'paypal', width: 51, position: '0 -121px' },
  { method: 'mc', width: 35, position: '-142px -149px' },
  { method: 'ideal', width: 25, position: '-96px -28px' },
  { method: 'klarna', width: 44, position: '-50px -149px' },
  { method: 'amex', width: 42, position: '-81px -65px' },
  { method: 'applepay', width: 34, position: '-199px -84px' },
  { method: 'ratepay', width: 35, position: '-138px -177px' },
  { method: 'jcb', width: 22, position: '-110px -177px' },
  { method: 'alipay', width: 44, position: '-57px -121px' },
  { method: 'diners', width: 30, position: '-74px -177px' },
  { method: 'maestro', width: 36, position: '-157px -121px' },
  { method: 'discover', width: 61, position: '-129px -56px' },
  { method: 'unionpay', width: 34, position: '-199px -140px' },
  { method: 'afterpaytouchfooter', width: 64, position: '-129px 0px' },
  { method: 'afterpay', width: 75, position: '0px -93px' },
  { method: 'dotpay', width: 53, position: '-129px -74px' },
  { method: 'giropay', width: 41, position: '-81px -93px' },
  { method: 'directebanking', width: 34, position: '-199px -112px' },
  { method: 'mrcash', width: 31, position: '-37px -177px' },
];
