import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const SocialPinterestInvertedIcon = ({
  color = colors.BLACK,
  hoverColor,
}: IconProps): ReactElement => (
  <Svg viewBox="0 0 1024 1024" $hoverColor={hoverColor}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill={color}>
        <path d="M512 1024A512 512 0 1 0 512 0a512 512 0 0 0 0 1024ZM372 778c-1-25 0-54 6-81l45-191s-11-22-11-55c0-52 30-91 68-91 32 0 47 24 47 52 0 33-20 81-31 125-9 37 19 68 56 68 66 0 111-86 111-187 0-77-52-134-146-134-107 0-173 79-173 168 0 30 9 52 23 68 6 8 7 11 5 20l-7 28c-2 9-10 12-18 9-49-20-72-74-72-134 0-100 84-219 251-219 135 0 223 97 223 201 0 137-77 240-190 240-38 0-73-20-86-44l-24 97c-8 27-22 54-36 75 0 0-9 12-28 4 0 0-13-5-13-19Z" />
      </g>
    </g>
  </Svg>
);

export default SocialPinterestInvertedIcon;
