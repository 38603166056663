import { memo, useState } from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import isEqual from 'react-fast-compare';
import type { GlobalTopNavigation as GlobalTopNavigationProps } from '../../../amplienceTypes/schemas/imported/global-top-navigation-schema';
import { NavLinks } from './navLinks/NavLinks';
import { HamburgerButton } from './sideBar/HamburgerButton';
import { Logo } from './logo/Logo';
import { colors, media } from '../core/styles';
import { SearchBox } from './searchBox/SearchBox';
import { SearchBoxV2 } from './searchBoxV2/SearchBoxV2';
import { ToggleSearch } from './searchBox/ToggleSearch';
import { ToggleSearchV2 } from './searchBoxV2/ToggleSearchV2';
import { LocaleSelectorButton } from './localeSelector/LocaleSelectorButton';
import { LoginButton } from './login/LoginButton';
import { WishListButton } from './miniWishlist/WishListButton';
import { MiniWishlist } from './miniWishlist/MiniWishlist';
import { ShoppingCartButton } from './miniCart/ShoppingCartButton';
import { MiniCart } from './miniCart/MiniCart';
import { SideNavHeader } from './sideBar/SideNavHeader';
import { MessageBoxNotification } from './messageBox/MessageBoxNotification';
import type { HeaderOpenModalProps, OpenedModal } from '../core/layouts/MasterLayout';
import { useGalleryContext } from '../../../utilities/context/dynamic/GalleryContext';
import { useNavActionContext } from '../../../utilities/context/dynamic/NavActionContext';
import { useStaticContext } from '../../../utilities/context/static/StaticContext';
import type { GlobalMessageBoxNotification } from '../../../amplienceTypes/schemas/imported/global-message-box-notification-schema';
import { useSearchContext } from '../../../utilities/context/dynamic/SearchContext';

interface TopNavigationProps {
  showCookieWall?: boolean;
  loginLabel?: string;
  smartBannerLoaded: boolean;
  smartBannerHeight: number;
  messageBoxNotification?: GlobalMessageBoxNotification;
}

const S = {
  Header: styled.div<{
    $galleryZoomIn: boolean;
    $showCookieWall: boolean;
    $smartBannerLoaded: boolean;
    $smartBannerHeight: number;
    $openedModal: OpenedModal;
  }>`
    display: ${({ $galleryZoomIn }) => ($galleryZoomIn ? 'none' : 'block')};
    position: fixed;
    font-size: 14px;
    height: var(--top-nav-height);
    left: 0;
    right: 0;
    top: ${({ $smartBannerLoaded, $smartBannerHeight }) =>
      $smartBannerLoaded && $smartBannerHeight ? `${$smartBannerHeight}px` : 0};
    width: 100%;
    z-index: 504;
    transform: translateY(0) translateZ(0);
    transition: transform 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    pointer-events: ${({ $showCookieWall }) => ($showCookieWall ? 'none' : 'auto')};

    @media ${media.lessThan('sm')} {
      display: ${({ $openedModal, $galleryZoomIn }) =>
        $openedModal === 'message-box-notification' || $galleryZoomIn ? 'none' : 'block'};
    }

    @media ${media.greaterThan('sm')} {
      z-index: 503;
      top: 0;
    }

    @media ${media.greaterThan('lg')} {
      display: block;
    }

    @media (prefers-reduced-motion) {
      transition: none;
    }

    .is-highstreet & {
      display: none;
    }
  `,

  MainContent: styled.div<{ $sideNavOpened: boolean }>`
    background-color: ${colors.NERO_GREY};

    @media ${media.lessThan('sm')} {
      transform: ${({ $sideNavOpened }) =>
        $sideNavOpened ? 'translate3d(314px, 0, 0)' : 'translate3d(0,0,0)'};
      transition-timing-function: ease-in-out;
      transition-duration: 0.25s;
      transition-property: left, width, transform;
      will-change: left, width;
      z-index: 1;
    }
  `,

  LeftNavWrapper: styled.div`
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap;
  `,

  LeftSearchButton: styled(ToggleSearch)`
    display: inline-block;

    .has-open-sideNavigation & {
      display: none;
    }

    @media ${media.greaterThan('sm')} {
      display: none;
    }
  `,

  LeftSearchButtonV2: styled.div`
    display: block;

    @media ${media.greaterThan('sm')} {
      display: none;
    }
  `,

  RightNavWrapper: styled.div`
    display: flex;
    gap: 4px;
    position: absolute;
    right: 0;
    top: 0;
    white-space: nowrap;
  `,

  RightSearchButton: styled(ToggleSearch)`
    display: none;

    @media ${media.greaterThan('sm')} {
      display: inline-block;
    }

    @media ${media.greaterThan('xl')} {
      display: none;
    }
  `,

  RightSearchButtonV2: styled.div`
    display: none;

    @media ${media.greaterThan('sm')} {
      display: block;
    }
  `,

  DesktopOnly: styled.div`
    display: none;
    margin: 0 10px;

    @media ${media.greaterThan('sm')} {
      display: flex;
    }
  `,
};

const TopNavigationComponent = ({
  _meta,
  showCookieWall = false,
  smartBannerLoaded,
  cart,
  wishlist,
  localeSelector,
  // To use later when we use amplience for nav links
  // navLinks,
  loginLabel,
  searchBoxPlaceholder,
  myAccountLabel,
  smartBannerHeight,
  messageBoxNotification,
  openedModal,
  setOpenedModal,
  searchSystemTexts,
}: TopNavigationProps & GlobalTopNavigationProps & HeaderOpenModalProps): ReactElement => {
  const [searchBoxActive, setSearchBoxActive] = useState<boolean>(false);
  const [wishlistHovered, setWishlistHovered] = useState<boolean>(false);

  const { t } = useTranslation('common', { keyPrefix: 'globalTopNavigation' });
  const { galleryZoomIn } = useGalleryContext();
  const { sideNavOpened } = useNavActionContext();
  const { hasSearchV2 } = useSearchContext();

  const {
    configuration: { showMessageBoxNotifications, enableWishlist },
  } = useStaticContext();

  return (
    <S.Header
      $galleryZoomIn={galleryZoomIn}
      $showCookieWall={showCookieWall}
      $smartBannerLoaded={smartBannerLoaded}
      $smartBannerHeight={smartBannerHeight}
      $openedModal={openedModal}
    >
      {hasSearchV2 ? (
        <SearchBoxV2 systemTexts={searchSystemTexts?.content} />
      ) : (
        <SearchBox
          searchBoxActive={searchBoxActive}
          setSearchBoxActive={setSearchBoxActive}
          placeholder={searchBoxPlaceholder}
        />
      )}
      <SideNavHeader
        localeSelector={localeSelector}
        openedModal={openedModal}
        setOpenedModal={setOpenedModal}
        _meta={_meta}
      />
      <S.MainContent suppressHydrationWarning $sideNavOpened={sideNavOpened}>
        <S.LeftNavWrapper>
          <HamburgerButton />
          {!sideNavOpened && !hasSearchV2 ? (
            <S.LeftSearchButton onClick={() => setSearchBoxActive(!searchBoxActive)} />
          ) : (
            <S.LeftSearchButtonV2>
              <ToggleSearchV2 placeholder={searchBoxPlaceholder} />
            </S.LeftSearchButtonV2>
          )}
          <NavLinks />
        </S.LeftNavWrapper>
        <Logo data-cs-capture="" />
        <S.RightNavWrapper>
          {hasSearchV2 ? (
            <S.RightSearchButtonV2>
              <ToggleSearchV2 placeholder={searchBoxPlaceholder} />
            </S.RightSearchButtonV2>
          ) : (
            <S.RightSearchButton onClick={() => setSearchBoxActive(!searchBoxActive)} />
          )}
          {localeSelector?.content && (
            <LocaleSelectorButton
              {...localeSelector?.content}
              openedModal={openedModal}
              setOpenedModal={setOpenedModal}
            />
          )}
          {messageBoxNotification && showMessageBoxNotifications && (
            <S.DesktopOnly>
              <MessageBoxNotification
                {...messageBoxNotification}
                openedModal={openedModal}
                setOpenedModal={setOpenedModal}
              />
            </S.DesktopOnly>
          )}
          <S.DesktopOnly>
            <LoginButton
              loginLabel={loginLabel}
              myAccountLabel={myAccountLabel || t('myAccountLabel')}
            />
          </S.DesktopOnly>
          {enableWishlist && (
            <WishListButton
              wishlistHovered={wishlistHovered}
              onWishlistHover={(hovered: boolean) => setWishlistHovered(hovered)}
            />
          )}
          <ShoppingCartButton />
        </S.RightNavWrapper>
      </S.MainContent>

      {wishlist?.content && (
        <MiniWishlist
          wishlistHovered={wishlistHovered}
          onWishlistHover={(hovered: boolean) => setWishlistHovered(hovered)}
          {...wishlist?.content}
        />
      )}

      {cart?.content && <MiniCart {...cart.content} />}
    </S.Header>
  );
};

export const TopNavigation = memo(TopNavigationComponent, isEqual);
