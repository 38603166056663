import type { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import type { HeaderItemPosition } from '../sideBar/SideNavHeader';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { a11yInvisibleStyle } from '../../common/styles';
import { NextLink } from '../../core/link/NextLink';

interface LogoProps {
  logoPosition?: HeaderItemPosition;
}

const style = {
  sideNavLogo: css`
    background-position: left center;
    margin-left: 16px;
    min-width: 140px;
    text-align: left;
    width: 50%;
  `,
};

const S = {
  Logo: styled(NextLink)<{ $logoPosition: HeaderItemPosition }>`
    margin: 0 auto;
    position: relative;
    width: 188px;
    background-image: var(--gstar-logo);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 18px;
    display: block;
    font-size: 0;
    height: 54px;
    opacity: 1;
    transition: opacity 0.5s ease-out 0s;
    cursor: pointer;

    ${({ $logoPosition }) => $logoPosition === 'side-header' && style.sideNavLogo};

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,

  A11yHomepage: styled.span`
    ${a11yInvisibleStyle}
  `,
};

export const Logo = ({ logoPosition = 'main-header' }: LogoProps): ReactElement => {
  const { locale } = useAppContext();
  const { pathname } = useRouter();
  const { accessibilityLabels } = useStaticContext();
  const { t } = useTranslation('common', { keyPrefix: 'accessibilityLabels' });

  const isHomePage = pathname === '/';

  return isHomePage && logoPosition === 'main-header' ? (
    <S.Logo
      href={`/${locale}`}
      $logoPosition={logoPosition}
      data-testid="gstar-logo"
      aria-label={accessibilityLabels?.logoLabel ?? t('logoLabel')}
    >
      <h1>{accessibilityLabels?.siteName ?? t('siteName')}</h1>
      <S.A11yHomepage>{accessibilityLabels?.homepage ?? t('homepage')}</S.A11yHomepage>
    </S.Logo>
  ) : (
    <S.Logo href={`/${locale}`} $logoPosition={logoPosition} data-testid="gstar-logo">
      {accessibilityLabels?.siteName ?? t('siteName')}
      <S.A11yHomepage>{accessibilityLabels?.homepage ?? t('homepage')}</S.A11yHomepage>
    </S.Logo>
  );
};
