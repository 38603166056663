import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import type { GlobalCookieWallSettings as GlobalCookieWallSettingsSchema } from '../../../amplienceTypes/schemas/imported/global-cookie-wall-settings-schema';
import { colors, media } from '../core/styles';
import { Typography } from '../core/typography/Typography';
import { Button } from '../core/button/Button';

type CookieWallElementProps = {
  changeSettingsHandler: () => void;
  yesButtonHandler: () => void;
};

const S = {
  CookieTitle: styled(Typography)`
    margin-bottom: 25px;
    font-size: 21px;
    line-height: 1em;
    text-transform: none;

    @media ${media.greaterThan('sm')} {
      margin-bottom: 30px;
    }
  `,

  CookieContent: styled(Typography)`
    margin-bottom: 13px;
  `,

  ChangeSettings: styled.div`
    text-decoration: underline;
    color: ${colors.ACCESSIBILITY_GREY};
    cursor: pointer;
    display: inline-block;
  `,

  Link: styled.a`
    text-decoration: underline;
    color: ${colors.ACCESSIBILITY_GREY};
  `,

  CookieWallQuestion: styled(Typography)`
    text-transform: none;
    margin-bottom: 13px;
    line-height: 20px;
  `,

  YesBtn: styled(Button)`
    width: 100%;
    order: 1;

    @media ${media.greaterThan('sm')} {
      order: 2;
    }
  `,
};

const CookieWallElement = ({
  changeSettingsHandler,
  yesButtonHandler,
  title,
  content,
  changeSettings,
  moreInfo,
  confirmation,
  yesBtn,
}: CookieWallElementProps & Partial<GlobalCookieWallSettingsSchema>) => {
  const { t } = useTranslation('common', { keyPrefix: 'globalCookieWallSettings' });

  return (
    <>
      <S.CookieTitle component="h1" variant="h3">
        {title || t('title')}
      </S.CookieTitle>
      <S.CookieContent component="div" variant="body">
        {(content || t('content'))?.split('[change settings]')[0]}
        <S.ChangeSettings onClick={changeSettingsHandler} data-testid="cookie-wall-change-settings">
          {changeSettings || t('changeSettings')}
        </S.ChangeSettings>
        {(content || t('content'))?.split('[change settings]')[1]}
        <br />
        <S.Link href="/cookie-statement">{moreInfo || t('moreInfo')}</S.Link>
      </S.CookieContent>
      <S.CookieWallQuestion variant="h4">{confirmation || t('confirmation')}</S.CookieWallQuestion>
      <S.YesBtn
        label={yesBtn || t('yesBtn')}
        ordinal="success"
        onClick={yesButtonHandler}
        testId="cookie-wall-accept"
      />
    </>
  );
};

export default CookieWallElement;
