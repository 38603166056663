import type { ReactElement } from 'react';
import styled from 'styled-components';
import type { Icon } from '../../core/button/Button';
import { Button } from '../../core/button/Button';
import { colors, media } from '../../core/styles';
import type { NavigationItem } from '../../../../utilities/graphql/codegen';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import { LEAN_LOCALE_REGEX } from '../../../../utilities/constants/regex';

type LinkType = 'Link' | 'Button';

export interface LinkProps {
  text: string;
  href: string;
  linkType?: LinkType;
  icon?: Icon;
}

const S = {
  Anchor: styled.a`
    display: block;
    text-decoration: none;
    color: ${colors.ACCESSIBILITY_GREY};
    white-space: nowrap;
    font-size: 13px;
    line-height: 32px;

    &:hover {
      color: ${colors.NERO_GREY};
    }

    &:focus {
      color: ${colors.ACCESSIBILITY_GREY};
    }
  `,

  Button: styled(Button)`
    margin-top: 15px;
    width: 100%;
    line-height: 38px;

    @media ${media.greaterThan('sm')} {
      line-height: 40px;
    }
  `,
};

export const GenericLink = ({
  label,
  target,
  url,
  styleModifier,
}: NavigationItem): ReactElement => {
  const { locale } = useAppContext();

  const addLocaleToUrl = (customUrl: string) => {
    if (customUrl.indexOf('http') === 0 || LEAN_LOCALE_REGEX.test(customUrl)) {
      return customUrl;
    }

    return `/${locale}${customUrl.indexOf('/') === 0 ? '' : '/'}${customUrl}`;
  };

  return (styleModifier ?? '').includes('footer-ctaLink') ? (
    <S.Button
      ordinal="secondary"
      label={label ?? ''}
      href={addLocaleToUrl(url ?? '')}
      icon="pin"
      testId="footer-linklist-button"
      hardReload
    />
  ) : (
    <S.Anchor
      href={addLocaleToUrl(url ?? '')}
      {...(target === 'NEWWINDOW' ? { target: '_blank' } : {})}
    >
      {label}
    </S.Anchor>
  );
};
