import type { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { animations, colors, media } from '../core/styles';

const S = {
  USPBarItem: styled.li`
    display: inline-block;
    min-width: 220px;
    box-sizing: border-box;
    height: var(--usp-bar-height);

    &:nth-child(n + 4) {
      display: inline-block;

      @media ${media.greaterThan('sm')} {
        display: none;
      }
    }

    &.scrollable {
      display: none;
    }

    &.active {
      display: inline-block;
      animation: ${animations.headerUspsScroll} 5200ms ease-in-out infinite;

      @media (prefers-reduced-motion) {
        animation: none;
      }
    }
  `,

  ItemWrapper: styled.div`
    padding: 0 30px;
  `,

  ItemLink: styled.a`
    line-height: var(--usp-bar-height);
    font-size: 13px;
    padding: 0 0.2rem;
    text-decoration: none;
    font-weight: 400;
    color: ${colors.ACCESSIBILITY_GREY};

    &:hover,
    &:focus {
      color: ${colors.NERO_GREY};
    }
  `,

  Item: styled.div`
    line-height: var(--usp-bar-height);
    font-size: 13px;
    padding: 0 0.2rem;
    font-weight: 400;
    color: ${colors.ACCESSIBILITY_GREY};
  `,
};

export interface USPItemContainerProps {
  url?: string;
  target?: string;
  children: ReactNode;
  className?: string;
  suppressHydrationWarning?: boolean;
}

export const USPItemContainer = ({
  url,
  target,
  children,
  ...rest
}: USPItemContainerProps): ReactElement => (
  <S.USPBarItem {...rest} data-qubit-id="usp-item">
    <S.ItemWrapper>
      {url ? (
        <S.ItemLink
          href={url ?? '#'}
          target={`${target === 'New Window/Tab' ? '_blank' : '_self'}`}
          data-qubit-id="usp-link"
        >
          {children}
        </S.ItemLink>
      ) : (
        <S.Item>{children}</S.Item>
      )}
    </S.ItemWrapper>
  </S.USPBarItem>
);
