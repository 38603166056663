import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const SocialEmailInvertedIcon = ({ color = colors.BLACK, hoverColor }: IconProps): ReactElement => (
  <Svg viewBox="0 0 1024 1024" $hoverColor={hoverColor}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill={color}>
        <path d="M512 1024A512 512 0 1 0 512 0a512 512 0 0 0 0 1024Zm36-453a56 56 0 0 1-78-2L224 372v347h576V366L548 571ZM242 305l259 208c5 5 12 5 16 2l257-210H242Z" />
      </g>
    </g>
  </Svg>
);

export default SocialEmailInvertedIcon;
