import { useEffect, useState } from 'react';
import Script from 'next/script';
import { useStaticContext } from '../../../utilities/context/static/StaticContext';
import { audioEyeHashes } from '../../../utilities/constants/audioEye';
import logger from '../../../utilities/logger';
import { useDataLayerContext } from '../../../utilities/context/static/DataLayerContext';

export const AudioEye = () => {
  const { configuration } = useStaticContext();
  const { pushToDataLayer } = useDataLayerContext();
  const [audioEyeScript, setAudioEyeScript] = useState('');

  useEffect(() => {
    if (configuration.enableAudioEye) {
      if (!process.env.ENVIRONMENT) {
        logger.warn('ENVIRONMENT variable not set in env');

        return;
      }

      const audioEyeHash = audioEyeHashes[process.env.ENVIRONMENT];

      if (!audioEyeHash) {
        logger.warn("Can't find the relevant audio eye hash");

        return;
      }

      setAudioEyeScript(
        `!function(){var b=function(){window.__AudioEyeSiteHash = "${audioEyeHash}"; var a=document.createElement("script");a.src="https://wsmcdn.audioeye.com/aem.js";a.type="text/javascript";a.setAttribute("async","");document.getElementsByTagName("body")[0].appendChild(a)};"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",b):window.attachEvent&&window.attachEvent("onload",b):b()}();`
      );
    }
  }, [configuration.enableAudioEye]);

  useEffect(() => {
    let audioEyeWrapper: HTMLElement | null = null;
    let previousState: boolean | undefined;
    let mutationObserver: MutationObserver | null;
    const bodyEl = document.querySelector('body');

    const eventTracker = (event: MouseEvent) => {
      const { toolbar: { active: isActive = undefined } = {} } = window?.AudioEye ?? {};
      const audioEyeEvent: {
        events: {
          category: string;
          action: string;
          label?: string;
        };
        event: string;
      } = {
        events: {
          category: 'accessibility_toolkit',
          action: 'click',
        },
        event: 'accessibility_toolkit',
      };

      const label = (event?.target as HTMLElement)?.shadowRoot?.activeElement?.getAttribute(
        'aria-describedby'
      );

      if (isActive && label) {
        audioEyeEvent.events.label = label
          .replace('.option-description', '')
          .replace('text-size', 'text size')
          .replace('color', 'color shift')
          .replace('fonts', 'font');
        pushToDataLayer(audioEyeEvent);
      } else if (previousState !== isActive || (!previousState && !isActive)) {
        audioEyeEvent.events.action = isActive === false ? 'close' : 'open';
        delete audioEyeEvent.events.label;
        pushToDataLayer(audioEyeEvent);
      }

      previousState = isActive;
    };

    if (bodyEl) {
      const onAudioEyeLoaded = (mutationList: MutationRecord[], observer: MutationObserver) => {
        if (audioEyeScript) {
          audioEyeWrapper = document.getElementById('ae_app');

          if (!audioEyeWrapper) {
            return;
          }

          audioEyeWrapper.addEventListener('click', eventTracker);
          observer.disconnect();
        }
      };

      mutationObserver = new MutationObserver(onAudioEyeLoaded);
      mutationObserver.observe(bodyEl, { attributes: true, childList: true, subtree: true });
    }

    return () => {
      mutationObserver?.disconnect();
      audioEyeWrapper?.removeEventListener('click', eventTracker);
    };
  }, [audioEyeScript, pushToDataLayer]);

  return audioEyeScript ? (
    <Script id="audio-eye-script" type="text/javascript" async aria-hidden aria-live="off">
      {audioEyeScript}
    </Script>
  ) : (
    <></>
  );
};
