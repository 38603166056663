import { Environments } from '.';

const gtmStagingConfig = {
  containerId: 'GTM-PPGBGR8',
  authId: 'bh-lXppDHi_K7lhhZaRzLw',
  prevId: 'env-5',
};
const gtmAcceptanceConfig = {
  containerId: 'GTM-PPGBGR8',
  authId: 'M5qKMvr22Rnw_e7oKkn7og',
  prevId: 'env-7',
};
const gtmProductionConfig = { containerId: 'GTM-PPGBGR8' };
// GTM and Smartserve script ids (Keys are from ENVIRONMENT variable)

export const gtmIds: { [k: string]: { containerId: string; authId?: string; prevId?: string } } = {
  [Environments.DEV]: gtmStagingConfig,
  [Environments.TST]: gtmStagingConfig,
  [Environments.ACC]: gtmAcceptanceConfig,
  [Environments.PRD]: gtmProductionConfig,
};
