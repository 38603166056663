import { useState, useEffect } from 'react';
import { useEffectOnce } from '../../utilities/hooks';
import { storageAvailable } from '../../utilities/helpers';
import {
  NEWSLETTER_OVERLAY_PAGE_VIEWS,
  NEWSLETTER_OVERLAY_SHOWN,
  NEWSLETTER_OVERLAY_START_TIME,
} from '../../utilities/constants';
import { useUserContext } from '../../utilities/context/dynamic/UserContext';

const PAGE_VIEW_TO_DISPLAY = 2;
const getCurrentPageView = () =>
  parseInt(localStorage.getItem(NEWSLETTER_OVERLAY_PAGE_VIEWS) || '', 10) || 0;

/**
 * A custom hook that provides logic for Newsletter Overlay component (a fallback for Exponea newsletter overlay)
 * @param excludeNewletterOverlayPageview A flag to indicate that the current page should be excluded from page view count
 * @returns { showNewsletterOverlay, closeNewsletterOverlay }
 */
export const useNewsletterOverlay = () => {
  const [showNewsletterOverlay, setShowNewsletterOverlay] = useState<boolean>(false);
  const { user } = useUserContext();
  const userType = user?.analyticsCustomerType;

  const isLocalStorageAvailable = storageAvailable('localStorage');

  useEffectOnce(() => {
    let pageViewCount = getCurrentPageView();

    pageViewCount += 1;

    const setNewsletterOverlayStartTime = (): void => {
      if (!isLocalStorageAvailable) {
        return;
      }

      if (localStorage.getItem(NEWSLETTER_OVERLAY_START_TIME) === null) {
        localStorage.setItem(NEWSLETTER_OVERLAY_START_TIME, new Date().toISOString());
      }
    };

    if (isLocalStorageAvailable) {
      localStorage.setItem(NEWSLETTER_OVERLAY_PAGE_VIEWS, `${pageViewCount}`);
      setNewsletterOverlayStartTime();
    }
  });

  useEffect(() => {
    const pageViewCount = getCurrentPageView();

    const isNewsletterOverlayWithin2h = (): boolean => {
      if (!isLocalStorageAvailable) {
        return false;
      }

      try {
        const twoHoursInMillis = 2 * 60 * 60 * 1000;
        const startTime = new Date(localStorage.getItem(NEWSLETTER_OVERLAY_START_TIME) as string);
        const passedTime = new Date().getTime() - startTime.getTime();

        return passedTime <= twoHoursInMillis;
      } catch {
        return false;
      }
    };

    const isExponeaBlocked = Boolean(!window?.exponea);
    const isWithin2h = isNewsletterOverlayWithin2h();

    const newsletterShownFlag: boolean = isLocalStorageAvailable
      ? localStorage.getItem(NEWSLETTER_OVERLAY_SHOWN) !== null
      : false;

    if (
      isWithin2h &&
      !newsletterShownFlag &&
      isExponeaBlocked &&
      userType === 'new' &&
      pageViewCount >= PAGE_VIEW_TO_DISPLAY
    ) {
      setShowNewsletterOverlay(true);

      if (isLocalStorageAvailable) {
        localStorage.setItem(NEWSLETTER_OVERLAY_SHOWN, 'true');
      }
    }
  }, [userType, isLocalStorageAvailable]);

  const closeNewsletterOverlay = () => {
    setShowNewsletterOverlay(false);
  };

  return { showNewsletterOverlay, closeNewsletterOverlay };
};
