import type { ReactElement } from 'react';
import styled from 'styled-components';
import { Newsletter } from '../newsletter/Newsletter';
import { GenericLinkPanel } from './genericLinkList/GenericLinkPanel';
import { MetaInfo } from './metainfo/MetaInfo';
import { AppStoreLinks } from './appStoreLinks/AppStoreLinks';
import { SocialLinks } from './socialLinks/SocialLinks';
import type { GlobalFooterNavigation } from '../../../amplienceTypes/schemas/imported/global-footer-navigation-schema';
import { useLazyLoading } from '../../../utilities/hooks';
import { media } from '../core/styles';
import { useStaticContext } from '../../../utilities/context/static/StaticContext';

const S = {
  Container: styled.div`
    min-height: 1px;
  `,

  FooterNavigation: styled.div`
    margin: 0 auto;
    max-width: 984px;
  `,

  SpaceContainer: styled.div`
    @media ${media.greaterThan('sm')} {
      height: 50px;
    }
  `,
};

interface FooterNavigationInterface extends GlobalFooterNavigation {
  amplienceShowAppStoreLinks?: boolean;
}

export const FooterNavigation = ({
  appStoreLinks,
  newsletter,
  amplienceShowAppStoreLinks,
}: FooterNavigationInterface): ReactElement => {
  const {
    configuration: { activeApp },
  } = useStaticContext();
  const showAppStoreLinks = activeApp && amplienceShowAppStoreLinks;
  const { ref, isIntersecting } = useLazyLoading();

  const renderComponent = () => (
    <>
      <S.SpaceContainer />
      <S.FooterNavigation>
        {newsletter?.content && <Newsletter {...newsletter.content} />}
      </S.FooterNavigation>
      {appStoreLinks?.content && showAppStoreLinks && <AppStoreLinks {...appStoreLinks.content} />}
      <S.FooterNavigation>
        <GenericLinkPanel />
        <SocialLinks />
        <MetaInfo />
      </S.FooterNavigation>
    </>
  );

  return <S.Container ref={ref}>{isIntersecting && renderComponent()}</S.Container>;
};
