import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { colors } from '../styles';
import { Svg } from './styles';

const GStarLogo = ({ color = colors.WHITE }: IconProps): ReactElement => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 1500 250"
  >
    <g>
      <path
        fill={color}
        d="M1415.99,164.4c27.93-10.44,47.81-37.52,47.51-69.12c-0.39-40.19-33.74-72.28-73.93-72.28H1230.5
		c-3.31,0-6,2.69-6,6v192c0,3.31,2.69,6,6,6h34c3.31,0,6-2.69,6-6v-52h91.42l41.45,54.82c1.51,2,3.87,3.18,6.38,3.18h45.55
		c3.3,0,5.18-3.78,3.19-6.41L1415.99,164.4z M1270.5,69h120c14.89,0,27,12.11,27,27s-12.11,27-27,27h-120V69z"
      />
      <path
        fill={color}
        d="M286.5,102h-132c-3.31,0-6,2.69-6,6v34c0,3.31,2.69,6,6,6h92v16.5c0,9.11-7.39,16.5-16.5,16.5H110.5
		c-15.46,0-28-12.54-28-28V97c0-15.46,12.54-28,28-28h167c3.31,0,6-2.69,6-6V29c0-3.31-2.69-6-6-6h-167c-40.87,0-74,33.13-74,74v56
		c0,40.87,33.13,74,74,74H230c34.52,0,62.5-27.98,62.5-62.5V108C292.5,104.69,289.81,102,286.5,102z"
      />
      <path
        fill={color}
        d="M1112.5,23h-97c-40.87,0-74,33.13-74,74v124c0,3.31,2.69,6,6,6h34c3.31,0,6-2.69,6-6v-52h153v52
		c0,3.31,2.69,6,6,6h34c3.31,0,6-2.69,6-6V97C1186.5,56.13,1153.37,23,1112.5,23z M987.5,123V97c0-15.46,12.54-28,28-28h97
		c15.46,0,28,12.54,28,28v26H987.5z"
      />
      <path
        fill={color}
        d="M614,102H498.71c-9,0-16.35-7.25-16.5-16.22C482.05,76.55,489.91,69,499.14,69H649.5c3.31,0,6-2.69,6-6V29
		c0-3.31-2.69-6-6-6H496.71c-34.79,0-63.02,28.57-62.49,63.47c0.52,34.29,29.14,61.53,63.44,61.53H613c9,0,16.35,7.25,16.5,16.22
		c0.15,9.23-7.71,16.78-16.93,16.78H449.5c-3.31,0-6,2.69-6,6v34c0,3.31,2.69,6,6,6h163.55c34.3,0,62.92-27.24,63.44-61.53
		C677.03,130.57,648.79,102,614,102z"
      />
      <path
        fill={color}
        d="M912.5,23h-216c-3.31,0-6,2.69-6,6v34c0,3.31,2.69,6,6,6h85v152c0,3.31,2.69,6,6,6h34c3.31,0,6-2.69,6-6V69h85
		c3.31,0,6-2.69,6-6V29C918.5,25.69,915.81,23,912.5,23z"
      />
      <path
        fill={color}
        d="M340.5,148h51c3.31,0,6-2.69,6-6v-34c0-3.31-2.69-6-6-6h-51c-3.31,0-6,2.69-6,6v34
		C334.5,145.31,337.19,148,340.5,148z"
      />
    </g>
  </Svg>
);

export default GStarLogo;
