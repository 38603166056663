import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { Button } from '../../../components/shared/core/button/Button';
import { useExponeaPushNotification } from './useExponeaPushNotification';
import type { GlobalExponeaPushNotificationPopup as GlobalExponeaPushNotificationPopupSchema } from '../../../amplienceTypes/schemas/imported/global-exponea-push-notification-popup-schema';
import { colors } from '../../../components/shared/core/styles';

const S = {
  NotificationLayer: styled.div<{ $visible: boolean }>`
    background-color: ${colors.WHITE};
    width: 355px;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 9999;
    transition: transform 0.5s;
    transform: ${({ $visible }) => ($visible ? `translateX(0)` : `translateX(100%)`)};
  `,

  Body: styled.div`
    padding: 38px 48px 28px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    position: relative;
  `,

  CloseButton: styled(Button)`
    position: absolute;
    top: 0;
    right: 0;
    min-width: auto;
    padding: 0;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,

  Title: styled.h2`
    font-size: 24px;
    font-weight: 900;
    text-transform: uppercase;
  `,

  Text: styled.div`
    font-size: 16px;
  `,

  ActionButtonsWrapper: styled.div`
    justify-content: start;
    align-items: center;
    font-style: normal;
    display: flex;
    gap: 12px;
  `,

  ActionButton: styled(Button)`
    padding: 10px 16px;
    min-width: auto;
    line-height: 1;
  `,

  Note: styled.div`
    font-size: 14px;
  `,
};

export const ExponeaPushNotificationsPopup = ({
  cta,
  title,
  message,
  agree,
  reject,
  note,
}: GlobalExponeaPushNotificationPopupSchema) => {
  const [popupVisible, setPopupVisible] = useState(false);
  const { agreeNotifications, rejectNotifications } = useExponeaPushNotification(setPopupVisible);
  const { t } = useTranslation('common', { keyPrefix: 'exponeaPopup' });

  const onClose = () => setPopupVisible(false);

  return (
    <S.NotificationLayer $visible={popupVisible}>
      <S.Body>
        <S.CloseButton icon="close" ariaLabel="close" onClick={onClose} />

        <S.Text>{cta || t('cta')}</S.Text>

        <S.Title>{title || t('title')}</S.Title>

        <S.Text>{message || t('message')}</S.Text>

        <S.ActionButtonsWrapper>
          <S.ActionButton
            type="button"
            ordinal="success"
            label={agree || t('agree')}
            textTransform="uppercase"
            onClick={agreeNotifications}
          />

          <S.ActionButton
            type="button"
            ordinal="tertiary"
            label={reject || t('reject')}
            textTransform="capitalize"
            onClick={rejectNotifications}
          />
        </S.ActionButtonsWrapper>

        <S.Note>{note || t('note')}</S.Note>
      </S.Body>
    </S.NotificationLayer>
  );
};
