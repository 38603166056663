import { Environments } from '.';

// Audioeye Hashes
const audioEyeStagingHash = 'cd5e0739ae13f3e960b5de4482c8c86f';
const audioEyeProductionHash = 'c8f36ff6df92592d93859d81570c323b';

export const audioEyeHashes: { [k: string]: string } = {
  [Environments.DEV]: audioEyeStagingHash,
  [Environments.TST]: audioEyeStagingHash,
  [Environments.ACC]: audioEyeStagingHash,
  [Environments.PRD]: audioEyeProductionHash,
};
