import type { ReactElement } from 'react';
import Script from 'next/script';
import { Environments } from '../../../utilities/constants';
import { gtmIds } from '../../../utilities/constants/googleAnalytics';
import { useStaticContext } from '../../../utilities/context/static/StaticContext';
import type { Configuration } from '../../../utilities/graphql/codegen';

const getAnalyticsDomain = ({ enableCLTAnalytics }: Configuration) =>
  enableCLTAnalytics ? 'clt.g-star.com' : 'www.googletagmanager.com';

const GTMLink = (): ReactElement => {
  const { configuration } = useStaticContext();
  const analyticsDomain = getAnalyticsDomain(configuration);

  return <link rel="preconnect" href={`https://${analyticsDomain}`} crossOrigin="" />;
};
const GTMHeadScript = (): ReactElement => {
  const { configuration } = useStaticContext();

  if (!process.env.ENVIRONMENT) {
    // ENVIRONMENT variable not set in env
    return <></>;
  }

  const analyticsDomain = getAnalyticsDomain(configuration);
  const gtmIdVariables = gtmIds[process.env.ENVIRONMENT];
  const gtmParams =
    Environments[process.env.ENVIRONMENT as keyof typeof Environments] === Environments.PRD
      ? ''
      : `&gtm_auth=${gtmIdVariables.authId}&gtm_preview=${gtmIdVariables.prevId}&gtm_cookies_win=x`;

  return (
    <Script id="gtm-head-script" strategy="afterInteractive">
      {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://${analyticsDomain}/gtm.js?id='+i+dl+'${gtmParams}';f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmIdVariables.containerId}');
      `}
    </Script>
  );
};

const GTMNoScript = (): ReactElement => {
  const { configuration } = useStaticContext();

  if (!process.env.ENVIRONMENT) {
    // ENVIRONMENT variable not set in env
    return <></>;
  }

  const analyticsDomain = getAnalyticsDomain(configuration);
  const gtmIdVariables = gtmIds[process.env.ENVIRONMENT];
  const gtmParams =
    Environments[process.env.ENVIRONMENT as keyof typeof Environments] === Environments.PRD
      ? ''
      : `&gtm_auth=${gtmIdVariables.authId}&gtm_preview=${gtmIdVariables.prevId}&gtm_cookies_win=x`;

  return (
    <noscript>
      <iframe
        title="Google Tag Manager Noscript"
        src={`https://${analyticsDomain}/ns.html?id=${gtmIdVariables.containerId}${gtmParams}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </noscript>
  );
};

export { GTMLink, GTMHeadScript, GTMNoScript };
