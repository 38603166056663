import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const SocialTwitterInvertedIcon = ({
  color = colors.BLACK,
  hoverColor,
}: IconProps): ReactElement => (
  <Svg viewBox="0 0 1024 1024" $hoverColor={hoverColor}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill={color}>
        <path d="M512 1024A512 512 0 1 0 512 0a512 512 0 0 0 0 1024Zm229-630v16a334 334 0 0 1-517 283l28 2c56 0 107-19 147-51-52-1-95-35-110-82a124 124 0 0 0 53-2c-54-11-95-59-95-116v-1c16 8 34 14 54 14a118 118 0 0 1-37-157c58 71 145 118 244 123l-3-27a118 118 0 0 1 204-81c27-5 52-15 75-29-9 28-27 51-52 66 24-3 47-9 68-19-16 24-36 45-59 61Z" />
      </g>
    </g>
  </Svg>
);

export default SocialTwitterInvertedIcon;
