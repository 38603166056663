import type { ReactElement } from 'react';
import Image from 'next/legacy/image';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { media, colors } from '../../core/styles';
import { Typography } from '../../core/typography/Typography';
import { Button } from '../../core/button/Button';
import type { GlobalAppStoreLinks } from '../../../../amplienceTypes/schemas/imported/global-app-store-links-schema';

const S = {
  Wrapper: styled.section`
    text-align: center;
    padding: 30px 0;
    background-color: ${colors.WHITE};

    @media ${media.greaterThan('sm')} {
      padding: 50px 0;
    }
  `,

  Button: styled(Button)`
    margin-top: 8px;
  `,

  StoreLinks: styled.div`
    display: flex;
    gap: 16px;
    justify-content: center;
    margin-top: 34px;
  `,

  Link: styled.a`
    text-decoration: none;
  `,
};

export const AppStoreLinks = ({
  title,
  text,
  findOutMore,
  officialAppLink,
  appStoreLink,
  googleStoreLink,
}: GlobalAppStoreLinks): ReactElement => {
  const { t } = useTranslation('common', { keyPrefix: 'globalAppStoreLinks' });

  return (
    <S.Wrapper data-cs-capture="">
      <Typography component="h2" alignment="center" variant="h4">
        {title || t('title')}
      </Typography>
      <Typography component="p" alignment="center" variant="subtitle">
        {text || t('text')}
      </Typography>
      {officialAppLink && (
        <S.Button
          label={findOutMore || t('findOutMore')}
          ordinal="tertiary"
          href={officialAppLink}
        />
      )}
      <S.StoreLinks>
        {appStoreLink && (
          <S.Link href={appStoreLink} data-testid="footer-app-store-link">
            <Image
              src={`${process.env.CLOUDINARY_MEDIA_URL2}image/upload/f_auto,q_51,h_80/v02/19-2/app/us/App_Store_Badge.png`}
              decoding="async"
              loading="lazy"
              alt="Download on the App Store"
              width="119"
              height="40"
            />
          </S.Link>
        )}
        {googleStoreLink && (
          <S.Link href={googleStoreLink} data-testid="footer-google-store-link">
            <Image
              src={`${process.env.CLOUDINARY_MEDIA_URL2}image/upload/f_auto,q_51,h_80/v02/19-2/app/us/google_playstore.png`}
              decoding="async"
              loading="lazy"
              alt="Get it on Google Play"
              width="134"
              height="40"
            />
          </S.Link>
        )}
      </S.StoreLinks>
    </S.Wrapper>
  );
};
