import type { ReactElement } from 'react';
import styled from 'styled-components';
import SocialFacebookInvertedIcon from '../../core/icons/SocialFacebookInvertedIcon';
import SocialInstagramInvertedIcon from '../../core/icons/SocialInstagramInvertedIcon';
import SocialLinkedinInvertedIcon from '../../core/icons/SocialLinkedinInvertedIcon';
import SocialPinterestInvertedIcon from '../../core/icons/SocialPinterestInvertedIcon';
import SocialTwitterInvertedIcon from '../../core/icons/SocialTwitterInvertedIcon';
import SocialYoutubeInvertedIcon from '../../core/icons/SocialYoutubeInvertedIcon';
import SocialTiktokInvertedIcon from '../../core/icons/SocialTiktokInvertedIcon';
import SocialEmailInvertedIcon from '../../core/icons/SocialEmailInvertedIcon';
import { colors, media } from '../../core/styles';
import { LinkTarget } from '../../../../utilities/graphql/codegen';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';

const S = {
  Wrapper: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 16px;
    column-gap: 20px;
    width: 166px;
    margin: 40px auto 60px;

    @media ${media.greaterThan('sm')} {
      gap: 10px;
      width: 100%;
      margin: 50px 0 55px;
    }
  `,

  Icon: styled.div`
    width: 42px;
    height: 42px;

    @media ${media.greaterThan('sm')} {
      width: 32px;
      height: 32px;
    }
  `,

  Link: styled.a`
    text-decoration: none;
  `,
};

export const SocialLinks = (): ReactElement => {
  const { socialMediaChannels } = useStaticContext();

  const icons: { [k: string]: ReactElement } = {
    pinterest: (
      <SocialPinterestInvertedIcon
        color={colors.ACCESSIBILITY_GREY}
        hoverColor={colors.NERO_GREY}
      />
    ),
    linkedin: (
      <SocialLinkedinInvertedIcon color={colors.ACCESSIBILITY_GREY} hoverColor={colors.NERO_GREY} />
    ),
    facebook: (
      <SocialFacebookInvertedIcon color={colors.ACCESSIBILITY_GREY} hoverColor={colors.NERO_GREY} />
    ),
    youtube: (
      <SocialYoutubeInvertedIcon color={colors.ACCESSIBILITY_GREY} hoverColor={colors.NERO_GREY} />
    ),
    email: (
      <SocialEmailInvertedIcon color={colors.ACCESSIBILITY_GREY} hoverColor={colors.NERO_GREY} />
    ),
    twitter: (
      <SocialTwitterInvertedIcon color={colors.ACCESSIBILITY_GREY} hoverColor={colors.NERO_GREY} />
    ),
    instagram: (
      <SocialInstagramInvertedIcon
        color={colors.ACCESSIBILITY_GREY}
        hoverColor={colors.NERO_GREY}
      />
    ),
    tiktok: (
      <SocialTiktokInvertedIcon color={colors.ACCESSIBILITY_GREY} hoverColor={colors.NERO_GREY} />
    ),
  };

  return (
    <>
      <S.Wrapper data-testid="footer-social-share">
        {socialMediaChannels.map((socialMediaChannel, i) => (
          <S.Link
            href={socialMediaChannel.url || '#'}
            aria-label={socialMediaChannel.name || ''}
            target={socialMediaChannel.target === LinkTarget.Newwindow ? '_blank' : '_self'}
            key={i}
          >
            <S.Icon>{socialMediaChannel.name ? icons[socialMediaChannel.name] : 'No Icon'}</S.Icon>
          </S.Link>
        ))}
      </S.Wrapper>
    </>
  );
};
