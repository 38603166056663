import React, { useState, memo, useRef, useEffect, useCallback, useMemo } from 'react';
import type { ChangeEvent, FocusEvent, ReactElement } from 'react';
import styled, { css } from 'styled-components';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import isEqual from 'react-fast-compare';

import type { Maybe } from 'graphql/jsutils/Maybe';
import type { SearchSystemTexts } from '../../../../amplienceTypes/schemas/imported/search-system-texts-schema';
import { InputField } from '../../core/form';
import SearchIcon from '../../core/icons/SearchIcon';
import { media, colors } from '../../core/styles';
import { debounce } from '../../../../utilities/parsers';
import type { UseComponentSizeState } from '../../../../utilities/dom';
import { onEnter, onEscape, onTab } from '../../../../utilities/dom';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { useNavActionContext } from '../../../../utilities/context/dynamic/NavActionContext';
import { useSearchContext } from '../../../../utilities/context/dynamic/SearchContext';
import type { SearchResultsOverlayProps } from './SearchResultsOverlay';
import type { SearchResultsBackdropProps } from './SearchResultsBackdrop';

const SearchResultsOverlay = dynamic<SearchResultsOverlayProps>(
  () => import('./SearchResultsOverlay').then(mod => mod.SearchResultsOverlay),
  { ssr: false }
);

const SearchResultsBackdrop = dynamic<SearchResultsBackdropProps>(
  () => import('./SearchResultsBackdrop').then(mod => mod.SearchResultsBackdrop),
  { ssr: false }
);

const S = {
  Wrapper: styled.div<{ $isSearchBoxOpen: boolean }>`
    position: relative;

    ${({ $isSearchBoxOpen }) =>
      !$isSearchBoxOpen &&
      css`
        > div {
          pointer-events: none;
          user-select: none;
        }
      `}
    @media ${media.lessThan('sm')} {
      padding: 0;
    }
  `,

  SearchBox: styled.div<{
    $isSearchBoxOpen: boolean;
    $isSideNavOpen: boolean;
    $isWishlistEnabled: Maybe<boolean>;
    $searchToggleSize: UseComponentSizeState;
  }>`
    --search-toggle-left: ${({ $searchToggleSize: { left } }) => `${Math.round(left || 0)}px`};
    --search-toggle-width: ${({ $searchToggleSize: { width } }) => `${Math.round(width || 0)}px`};
    --search-box-transition-duration: 0.15s;
    --side-nav-transition-duration: ${({ $isSideNavOpen }) => ($isSideNavOpen ? '0.25s' : '0s')};
    --search-box-transition-delay: ${({ $isSideNavOpen }) =>
      $isSideNavOpen
        ? 'calc(var(--side-nav-transition-duration) - var(--search-box-transition-duration))'
        : '0s'};

    position: absolute;
    box-sizing: border-box;
    background-color: ${({ $isSearchBoxOpen }) => ($isSearchBoxOpen ? colors.WHITE : 'initial')};
    z-index: 1;
    top: 0;
    right: 0;
    left: var(--search-toggle-left);
    width: var(--search-toggle-width);
    height: var(--top-nav-height);
    transition: width var(--search-box-transition-duration) ease,
      left var(--search-box-transition-duration) ease,
      right var(--search-box-transition-duration) ease,
      background-color var(--search-box-transition-duration) ease,
      padding-left var(--search-box-transition-duration) ease;

    ${({ $isSearchBoxOpen }) =>
      $isSearchBoxOpen &&
      css`
        width: 100%;
        left: 0;
        right: 100%;
        padding-left: 32px;
      `}

    @media (prefers-reduced-motion) {
      transition: none;
    }

    @media ${media.lessThan('sm')} {
      padding: 0;
      transition-delay: var(--search-box-transition-delay), var(--search-box-transition-delay),
        var(--search-box-transition-delay), 0, var(--search-box-transition-delay);

      ${({ $isWishlistEnabled, $isSearchBoxOpen }) =>
        $isWishlistEnabled &&
        css`
          left: ${$isSearchBoxOpen ? '0px' : 'var(--search-toggle-left)'};
        `};
    }
  `,

  Form: styled.form`
    @media ${media.lessThan('sm')} {
      padding: 0 16px;
    }
  `,

  Input: styled(InputField)<{ $isSearchBoxOpen: boolean; $hidden: boolean }>`
    &.search-input {
      display: ${({ $hidden }) => ($hidden ? 'none' : 'block')};
      height: var(--top-nav-height);
    }

    input {
      background-color: ${({ $isSearchBoxOpen }) =>
        $isSearchBoxOpen ? colors.WHITE_SMOKE_GREY : 'initial'};
      color: ${colors.WHITE};
      position: absolute;
      left: 24px;
      top: 0;
      width: 100%;
      border: none;
      box-sizing: border-box;
      padding: 0;
      padding-right: 10px;
      font-size: 16px;
      margin-top: 7px;
      line-height: var(--top-nav-height);
      z-index: 1;
      padding-left: ${({ $isSearchBoxOpen }) => ($isSearchBoxOpen ? '48px' : 0)};
      font-weight: 300;
      text-transform: capitalize;

      &::placeholder {
        font-size: 16px;
        color: ${({ $isSearchBoxOpen }) =>
          $isSearchBoxOpen ? colors.ACCESSIBILITY_GREY : colors.WHITE};
      }

      &::-webkit-search-cancel-button {
        display: none;
      }

      ${({ $isSearchBoxOpen }) =>
        $isSearchBoxOpen &&
        css`
          left: 0;
          margin-top: 14px;
          color: ${colors.NERO_BLACK};
          width: calc(100% - 60px);
        `}

      @media ${media.lessThan('lg')} {
        display: ${({ $isSearchBoxOpen }) => ($isSearchBoxOpen ? 'initial' : 'none')};
        height: 40px;
      }
    }
  `,

  Icon: styled.i<{ $isSearchBoxOpen: boolean }>`
    width: 14px;
    min-width: 14px;
    left: ${({ $isSearchBoxOpen }) => ($isSearchBoxOpen ? 'calc(32px + 16px)' : '10px')};
    top: ${({ $isSearchBoxOpen }) => ($isSearchBoxOpen ? '26px' : '20px')};
    z-index: 2;
    position: absolute;
    cursor: initial;
    pointer-events: none;
    opacity: ${({ $isSearchBoxOpen }) => ($isSearchBoxOpen ? 1 : 0)};
    transition: opacity 0.15s ease;
    transition-delay: 0.15s;

    path {
      fill: ${({ $isSearchBoxOpen }) => ($isSearchBoxOpen ? colors.NERO_BLACK : colors.WHITE)};
    }

    @media ${media.lessThan('sm')} {
      width: 12px;
      left: ${({ $isSearchBoxOpen }) => ($isSearchBoxOpen ? '26px' : '10px')};
    }
  `,

  CloseButton: styled.button`
    position: absolute;
    top: 6px;
    right: 10px;
    width: 42px;
    height: var(--top-nav-height);
    border: none;
    cursor: pointer;
    z-index: 1;

    &::before {
      font-family: gstar-icons;
      content: '\\F13F';
      font-size: 16px;
      text-align: left;
    }

    @media ${media.lessThan('sm')} {
      right: 20px;
    }
  `,
};

export const MAX_RECENT_SEARCH_QUERIES = 5;
export const ALGOLIA_MIN_CHARS = 2;

export interface SearchBoxV2Props {
  systemTexts?: SearchSystemTexts;
}

const SearchBoxV2Component = ({ systemTexts }: SearchBoxV2Props): ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);

  const router = useRouter();
  const { t } = useTranslation('common', { keyPrefix: 'search.suggestions' });

  const { locale } = useAppContext();
  const { isSearchBoxOpen, setIsSearchBoxOpen, searchToggleSize } = useSearchContext();
  const { sideNavOpened } = useNavActionContext();
  const {
    configuration: { enableWishlist },
  } = useStaticContext();

  const storedRecentSearchQueries = useMemo(() => {
    try {
      const searchQueries = JSON.parse(localStorage.getItem('recentSearchQueries') ?? '[]');

      return Array.isArray(searchQueries) ? searchQueries : [];
    } catch (e) {}

    return [];
  }, []);

  const [searchQuery, setSearchQuery] = useState<string>('');

  // TODO: add in phase two
  // eslint-disable-next-line no-unused-vars , @typescript-eslint/no-unused-vars
  const [recentSearchQueries, setRecentSearchQueries] =
    useState<string[]>(storedRecentSearchQueries);

  const updateRecentSearchQueries = useCallback(
    (value: string) =>
      setRecentSearchQueries(prevRecentSearchQueries => {
        const searchQueries = [
          ...new Set(prevRecentSearchQueries.filter(e => e !== value).concat(value)),
        ].slice(-MAX_RECENT_SEARCH_QUERIES);

        localStorage.setItem('recentSearchQueries', JSON.stringify(searchQueries));

        return searchQueries;
      }),
    []
  );

  const closeSearchBox = useCallback(() => {
    if (isSearchBoxOpen) {
      setIsSearchBoxOpen(false);
      document.body.classList.remove('no-scroll');
    }
  }, [isSearchBoxOpen, setIsSearchBoxOpen]);

  const handleTab = useCallback(
    () =>
      // workaround to get the actual (instead of previously) focused element on tab key press
      setTimeout(() => {
        // focus first element again when tabbing out of the search box
        if (!document.activeElement?.closest('[data-search-box]')) {
          inputRef.current?.focus();
        }
      }, 0),
    []
  );

  const handleFormSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (searchQuery.length) {
        updateRecentSearchQueries(searchQuery);
        router.push(`/${locale}/search?q=${encodeURIComponent(searchQuery)}`).then(() => {
          closeSearchBox();
          inputRef.current?.blur();
        });
      }
    },
    [closeSearchBox, locale, router, searchQuery, updateRecentSearchQueries]
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent | React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key !== 'Escape') {
        event.stopPropagation();
      }
    },
    []
  );

  const handleInputChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => setSearchQuery(value.trim()),
    []
  );

  const debouncedHandleInputChangeRef = useRef(debounce(handleInputChange, 250));

  const handleFocus = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      const { target } = event;

      if (target.value !== searchQuery) {
        handleInputChange(event);
      }

      target.setSelectionRange(target.value.length, target.value.length);
      document.querySelector('[data-search-results-overlay]')?.scrollTo({ top: 0 });
    },
    [handleInputChange, searchQuery]
  );

  const updateSearchQuery = useCallback(
    (value: string) => {
      if (inputRef.current) {
        inputRef.current.value = value;
        inputRef.current.focus();
      }

      updateRecentSearchQueries(value);
      router.prefetch(`/${locale}/search?q=${encodeURIComponent(value)}`);
    },
    [locale, router, updateRecentSearchQueries]
  );

  useEffect(() => {
    const debouncedHandleInputChange = debouncedHandleInputChangeRef.current;

    // focus input when search box is opened
    if (isSearchBoxOpen) {
      inputRef.current?.focus();
    }
    // focus search toggle button when search box is closed using the escape key
    else if (document.activeElement?.closest('[data-search-box]')) {
      (document.querySelector('.search-toggle') as HTMLButtonElement)?.focus();
    }

    // cancel debounced handleInputChange when search box is closed
    return isSearchBoxOpen ? debouncedHandleInputChange.cancel : () => null;
  }, [isSearchBoxOpen]);

  useEffect(() => {
    if (isSearchBoxOpen) {
      const handleCloseSearchBox = onEscape(closeSearchBox);
      const handleTabs = onTab(handleTab);

      window.addEventListener('keydown', handleCloseSearchBox);
      window.addEventListener('keydown', handleTabs, true);

      return () => {
        window.removeEventListener('keydown', handleCloseSearchBox);
        window.removeEventListener('keydown', handleTabs, true);
      };
    }

    return () => null;
  }, [closeSearchBox, handleTab, isSearchBoxOpen]);

  return (
    <S.Wrapper $isSearchBoxOpen={isSearchBoxOpen} data-search-box>
      <S.SearchBox
        tabIndex={-1}
        $isSearchBoxOpen={isSearchBoxOpen}
        $isSideNavOpen={sideNavOpened}
        $isWishlistEnabled={enableWishlist}
        $searchToggleSize={searchToggleSize}
      >
        <S.Icon $isSearchBoxOpen={isSearchBoxOpen}>
          <SearchIcon />
        </S.Icon>

        <S.Form
          action={`/${locale}/search?q=${encodeURIComponent(searchQuery)}`}
          method="GET"
          role="search"
          className="search-form"
          onSubmit={handleFormSubmit}
        >
          <S.Input
            className="search-input"
            ref={inputRef}
            type="search"
            name="q"
            placeholder={systemTexts?.searchbar?.placeholder || t('searchbar.placeholder')}
            onChange={debouncedHandleInputChangeRef.current}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            autoComplete="off"
            testId="search-input"
            data-cs-capture=""
            tabindex={0}
            aria-label={systemTexts?.searchbar?.placeholder || t('searchbar.placeholder')}
            $isSearchBoxOpen={isSearchBoxOpen}
            $hidden={!isSearchBoxOpen}
          />
        </S.Form>

        {isSearchBoxOpen && (
          <S.CloseButton
            onClick={closeSearchBox}
            onKeyDown={onEnter(closeSearchBox)}
            tabIndex={0}
            data-cs-capture=""
          />
        )}
      </S.SearchBox>

      <SearchResultsOverlay
        systemTexts={systemTexts}
        isSearchBoxOpen={isSearchBoxOpen}
        searchQuery={searchQuery}
        updateSearchQuery={updateSearchQuery}
        // recentSearchQueries={recentSearchQueries} // TODO: add in phase two
      />

      <SearchResultsBackdrop isSearchBoxOpen={isSearchBoxOpen} closeSearchBox={closeSearchBox} />
    </S.Wrapper>
  );
};

export const SearchBoxV2 = memo(SearchBoxV2Component, isEqual);
