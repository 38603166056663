import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const PreferencesIcon = ({ color = colors.BLACK }: IconProps): ReactElement => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    overflow="inherit"
    stroke={color}
  >
    <path
      fill="none"
      d="M13.2296 8.71798L13.1945 8.99878L13.4171 9.17342L15.0333 10.4411L13.5212 13.061L11.6168 12.2923L11.3501 12.1847L11.123 12.3611C10.746 12.654 10.3323 12.8956 9.88485 13.0827L9.62248 13.1924L9.58263 13.4739L9.29592 15.5H6.26747L5.98077 13.4739L5.94092 13.1924L5.67854 13.0827C5.23305 12.8965 4.81821 12.6509 4.43799 12.3593L4.21139 12.1855L3.94656 12.2923L2.04224 13.061L0.530065 10.4411L2.14628 9.17342L2.36894 8.99878L2.33384 8.71798C2.30306 8.47175 2.2817 8.23466 2.2817 8C2.2817 7.76534 2.30306 7.52825 2.33384 7.28202L2.36894 7.00122L2.14628 6.82658L0.530065 5.55889L2.04224 2.93905L3.94656 3.70766L4.21326 3.8153L4.4404 3.63888C4.81743 3.34605 5.23112 3.10435 5.67854 2.91731L5.94092 2.80763L5.98077 2.52606L6.26747 0.5H9.29992L9.58663 2.52606L9.62648 2.80763L9.88885 2.91731C10.3344 3.10355 10.7492 3.34912 11.1294 3.64074L11.356 3.81455L11.6208 3.70766L13.5252 2.93905L15.0373 5.5588L13.4176 6.82623L13.1944 7.00085L13.2296 7.28202C13.2603 7.52825 13.2817 7.76534 13.2817 8C13.2817 8.23466 13.2603 8.47175 13.2296 8.71798ZM5.2857 8C5.2857 8.79011 5.39215 9.48879 5.83501 9.97079C6.28892 10.4648 6.97428 10.604 7.7817 10.604C8.58911 10.604 9.27448 10.4648 9.72838 9.97079C10.1712 9.48879 10.2777 8.79011 10.2777 8C10.2777 7.20989 10.1712 6.51121 9.72838 6.02921C9.27448 5.53519 8.58911 5.396 7.7817 5.396C6.97428 5.396 6.28892 5.53519 5.83501 6.02921C5.39215 6.51121 5.2857 7.20989 5.2857 8Z"
    />
  </Svg>
);

export default PreferencesIcon;
