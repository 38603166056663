import type { ReactElement } from 'react';
import Image from 'next/legacy/image';
import styled from 'styled-components';
import { Typography } from '../../core/typography/Typography';
import { colors, media } from '../../core/styles';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import { CHINA_CODE } from '../../../../utilities/constants';
import type { PaymentMethods } from '../../../../utilities/graphql/codegen';
import { useMetaInfoPaymentMethodsQuery } from '../../../../utilities/graphql/codegen';
import { createQueryParams } from '../../../../utilities/parsers';
import { paymentIconStyles } from '../../../../utilities/constants/paymentIcons';

const S = {
  PaymentMethod: styled.div`
    position: relative;
    width: auto;
    padding: 0 0 10px;
    margin: 0 0 45px;
    text-align: center;
    border-top: 1px solid ${colors.BORDER_GREY};
    border-bottom: 1px solid ${colors.BORDER_GREY};
    clear: both;
  `,

  PaymentMethodLink: styled.a`
    display: inline-block;
    color: ${colors.ACCESSIBILITY_GREY};
  `,

  PaymentMethodIcon: styled.span<{ $width: number; $backgroundPosition: string }>`
    display: inline-block;
    height: 22px;
    background-image: url('/_fes/${process.env.BUILD_TIME}/img/sprites/payment.png');
    background-repeat: no-repeat;
    font-size: 0;
    width: ${({ $width }) => $width}px;
    background-position: ${({ $backgroundPosition }) => $backgroundPosition};
    margin: 15px 8px 5px;

    @media ${media.greaterThan('sm')} {
      margin: 20px 20px 0;
    }
  `,

  CopyRight: styled(Typography)`
    font-size: 11px;
    line-height: 34px;
    margin-bottom: 20px;

    @media ${media.greaterThan('sm')} {
      margin-bottom: 0;
    }
  `,

  CnGovReq: styled(Typography)`
    margin-bottom: 20px;
    line-height: 34px;
    font-size: 11px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  `,

  CnLegalReq: styled(Typography)`
    height: 34px;
    min-width: 34px;
    padding-right: 39px;
    font-size: 11px;
    line-height: 34px;
    background: url('/_fes/${process.env.BUILD_TIME}/img/cn_legal_req.gif') no-repeat right;
    text-align: center;
  `,

  CnSzfwReq: styled.a`
    text-decoration: none;
    display: flex;

    @media ${media.lessThan('sm')} {
      flex-basis: 100%;
      justify-content: center;
    }
  `,

  Link: styled.a`
    text-decoration: none;
    color: ${colors.ACCESSIBILITY_GREY};
  `,
};

const MetaInfoComponent = (): ReactElement => {
  const { country, locale } = useAppContext();

  const { baseSiteId } = createQueryParams(locale);
  const { loading, error, data } = useMetaInfoPaymentMethodsQuery({
    variables: {
      baseSiteId,
      locale,
    },
    ssr: false,
  });

  if (loading || error) {
    return <></>;
  }

  const paymentMethods = data?.paymentMethods as PaymentMethods[];

  const paymentUrl =
    paymentMethods.find(paymentMethod => paymentMethod.url)?.url ?? '/customer-service/payments';

  return (
    <>
      {country !== CHINA_CODE && paymentMethods?.length ? (
        <S.PaymentMethod data-testid="footer-payment-methods">
          <S.PaymentMethodLink href={paymentUrl} aria-label="Footer payment methods">
            {paymentMethods.map((paymentMethod, i) => {
              const iconStyle = paymentIconStyles.find(
                iStyle => iStyle.method === paymentMethod.code
              );

              return iconStyle ? (
                <S.PaymentMethodIcon
                  $width={iconStyle.width}
                  $backgroundPosition={iconStyle.position}
                  key={i}
                />
              ) : null;
            })}
          </S.PaymentMethodLink>
        </S.PaymentMethod>
      ) : (
        <></>
      )}
      <S.CopyRight component="p" variant="body" alignment="center" testId="footer-copyright">
        &copy; G-STAR RAW {new Date().getFullYear()}
      </S.CopyRight>
      {country === CHINA_CODE && (
        <S.CnGovReq variant="body">
          <S.Link href="https://beian.miit.gov.cn/" target="_blank">
            沪ICP备14007988号-1
          </S.Link>
          <S.CnLegalReq variant="body" component="span">
            &nbsp;上海工商
          </S.CnLegalReq>
          &nbsp;
          <S.CnSzfwReq href="https://search.szfw.org/cert/l/CX20150511007725010050" target="_blank">
            <Image
              src="/img/ceca-icon.jpg"
              decoding="async"
              loading="lazy"
              alt="szfw requirement"
              width="95"
              height="34"
              layout="fixed"
            />
          </S.CnSzfwReq>
        </S.CnGovReq>
      )}
    </>
  );
};

export const MetaInfo = MetaInfoComponent;
