import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { Typography } from '../core/typography/Typography';
import { Radio, Checkbox, InputField, RadioGroup } from '../core/form';
import { Button } from '../core/button/Button';
import { media, colors } from '../core/styles';
import type { GlobalNewsletter as GlobalNewsletterSchema } from '../../../amplienceTypes/schemas/imported/global-newsletter-schema';
import { useAppContext } from '../../../utilities/context/static/AppContext';
import {
  EntryPoint,
  NewsletterSubscribeSuccessType,
  SubscribeStatusCode,
} from '../../../utilities/graphql/codegen';
import { usePDPContext } from '../../../utilities/context/static/PDPContext';
import { PRIVACY_POLICY_VARIABLE } from '../../../utilities/constants/amplienceVariables';
import { useDataLayerContext } from '../../../utilities/context/static/DataLayerContext';
import { useStaticContext } from '../../../utilities/context/static/StaticContext';
import { getFormSchema } from '../../../utilities/constants/formSchema';
import { subscribeToGstar } from '../../../utilities/helpers';

type NewsletterType = {
  email: string;
  preferences: string;
  notification: string;
  updates: boolean;
};

const S = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 45px 30px;

    @media ${media.greaterThan('lg')} {
      padding: 40px 0 50px;
    }
  `,

  Form: styled.form`
    max-width: 550px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
  `,

  EmailSubscribe: styled.div`
    display: flex;
    flex-direction: column;

    @media ${media.greaterThan('sm')} {
      flex-direction: row;
    }
  `,

  Email: styled.div`
    margin-top: 19px;
    margin-bottom: 12px;
    width: 100%;
    box-sizing: border-box;

    @media ${media.greaterThan('sm')} {
      max-width: 390px;
      margin: 12px 15px 0 0;
    }
  `,

  Description: styled.div`
    margin: 10px 0 20px;
    text-align: center;

    & span {
      line-height: 20px;
    }

    @media ${media.greaterThan('sm')} {
      margin-bottom: 15px;
      margin-top: 0;
      line-height: 32px;
    }
  `,

  SubmitBtn: styled(Button)`
    font-size: 13px;
    width: 100%;
    padding: 0 10px;
    height: 48px;

    @media ${media.greaterThan('sm')} {
      flex: 1;
      margin-top: 32px;
      height: 40px;
    }
  `,

  NotificationCheckBox: styled.div<{ $visible: boolean }>`
    opacity: ${({ $visible }) => ($visible ? 1 : 0)};
    transition: opacity 0.2s linear;

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,

  PrivacyPolicyLink: styled.a`
    color: ${colors.ACCESSIBILITY_GREY};
    cursor: pointer;

    &:hover,
    &:focus {
      color: ${colors.NERO_GREY};
    }
  `,

  SubscribeResponse: styled.div`
    padding: 10px 0;
    color: ${colors.ACCESSIBILITY_GREY};
    font-size: 13px;
    line-height: 20px;
    text-align: center;
  `,
};

export const Newsletter = ({
  title,
  subtitle,
  bodyTitle,
  fashionLabel,
  menFashionLabel,
  womenFashionLabel,
  emailLabel,
  emailPlaceholder,
  subscribeBtn,
  description,
  privacyPolicy,
  newsletterSuccessMessageNewUser,
  newsletterSuccessMessageExistingUser,
}: GlobalNewsletterSchema): ReactElement => {
  const { t } = useTranslation('common');

  const {
    product: { subTargetAudience },
  } = usePDPContext();

  const [newsletterSubscribeSuccessType, setNewsletterSubscribeSuccessType] =
    useState<NewsletterSubscribeSuccessType>();
  const [visiblePrivacyCheckbox, setVisiblePrivacyCheckbox] = useState(false);
  const { country, pushToContentSquare } = useAppContext();
  const { pushToDataLayer } = useDataLayerContext();
  const { accessibilityLabels, formErrorLabels } = useStaticContext();
  const NewsletterSchema = getFormSchema({
    emailRequiredLabel:
      formErrorLabels?.emailRequiredLabel || t('globalFormErrorLabels.emailRequiredLabel'),
    emailInvalidLabel:
      formErrorLabels?.emailInvalidLabel || t('globalFormErrorLabels.emailInvalidLabel'),
    preferencesRequiredLabel:
      formErrorLabels?.preferencesRequiredLabel ||
      t('globalFormErrorLabels.preferencesRequiredLabel'),
    notificationRequiredLabel:
      formErrorLabels?.notificationRequiredLabel ||
      t('globalFormErrorLabels.notificationRequiredLabel'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    trigger,
    getValues,
    watch,
  } = useForm<NewsletterType>();

  useEffect(() => {
    const subscription = watch(async (value, { name, type }) => {
      if (name === 'email' && type === 'change') {
        return;
      }

      if (name === 'notification') {
        await trigger('notification');
      }

      setVisiblePrivacyCheckbox(
        !!(
          errors.email ||
          errors.preferences ||
          !(name !== 'notification' && !errors.notification) ||
          value.notification ||
          value.email
        )
      );
    });

    return () => subscription.unsubscribe();
  }, [errors.email, errors.notification, errors.preferences, trigger, watch]);

  const handleSubscribe: SubmitHandler<NewsletterType> = async values => {
    const entryPointId: EntryPoint = EntryPoint.Footer;
    const subscriptionPageTopic = 'topic_bf20Q4';

    try {
      const response = await subscribeToGstar({
        isNewsLetterSubscription: true,
        entryPoint: entryPointId,
        email: values.email,
        preferredCategory: values.preferences,
      });

      if (response?.subscribeStatusCode === SubscribeStatusCode.Success) {
        setNewsletterSubscribeSuccessType(response.newsletterSubscribeSuccessType);

        pushToDataLayer({
          events: {
            category: 'newsletter',
            action: 'subscribe',
            label: `NEWSLETTER_SUBSCRIPTION~${subscriptionPageTopic}`.toLowerCase(),
          },
          page: {
            countryCode: country.toUpperCase(),
          },
          event: 'newsletter-subscribe',
        });

        pushToContentSquare('trackPageEvent', 'Action | Subscribed to newsletter');
      } else {
        // eslint-disable-next-line no-console
        console.log(response?.errors);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const radios = [
    <Radio
      id="mens-fashion"
      label={menFashionLabel || t('globalNewsletter.menFashionLabel')}
      name="preferences"
      value="men"
      key="1"
      register={register}
      required={NewsletterSchema.preferences.required}
      error={errors.preferences && errors.preferences.message}
      testId="newsletter-preference-men"
      defaultChecked={subTargetAudience === 'men'}
      ariaLabel={accessibilityLabels?.manFashionPreference}
    />,
    <Radio
      id="womens-fashion"
      label={womenFashionLabel || t('globalNewsletter.womenFashionLabel')}
      name="preferences"
      value="women"
      key="2"
      register={register}
      required={NewsletterSchema.preferences.required}
      error={errors.preferences && errors.preferences.message}
      testId="newsletter-preference-women"
      defaultChecked={subTargetAudience === 'women'}
      ariaLabel={accessibilityLabels?.womanFashionPreference}
    />,
  ];

  const privacyPolicyEl = (
    <span>
      {(description || t('globalNewsletter.description') || '').split(PRIVACY_POLICY_VARIABLE)[0]}
      <S.PrivacyPolicyLink href="/help-info/privacy-policy">
        {privacyPolicy || t('globalNewsletter.privacyPolicy')}
      </S.PrivacyPolicyLink>
      {(description || t('globalNewsletter.description') || '').split(PRIVACY_POLICY_VARIABLE)[1]}
    </span>
  );

  return (
    <S.Wrapper data-testid="newsletter-component" data-cs-capture="">
      <Typography
        component="h2"
        variant="h3"
        alignment="center"
        testId="newsletter-title"
        data-cs-capture=""
      >
        {title || t('globalNewsletter.title')}
      </Typography>
      <S.Description data-testid="newsletter-intro" data-cs-capture="">
        <Typography component="span" variant="body" alignment="center" data-cs-capture="">
          {subtitle || t('globalNewsletter.subtitle')}&nbsp;
        </Typography>
        <Typography component="span" variant="h5" alignment="center" data-cs-capture="">
          {bodyTitle || t('globalNewsletter.bodyTitle')}
        </Typography>
      </S.Description>
      {newsletterSubscribeSuccessType ? (
        <S.SubscribeResponse
          dangerouslySetInnerHTML={{
            __html:
              (newsletterSubscribeSuccessType === NewsletterSubscribeSuccessType.NewCustomer
                ? newsletterSuccessMessageNewUser ||
                  t('globalNewsletter.newsletterSuccessMessageNewUser')
                : newsletterSuccessMessageExistingUser ||
                  t('globalNewsletter.newsletterSuccessMessageExistingUser')) || '',
          }}
        />
      ) : (
        <S.Form onSubmit={handleSubmit(handleSubscribe)}>
          {radios && (
            <RadioGroup
              label={fashionLabel || t('globalNewsletter.fashionLabel')}
              radios={radios}
              error={errors.preferences && errors.preferences.message}
              testId="newsletter-preference"
              data-cs-capture=""
              role="radiogroup"
            />
          )}
          <S.EmailSubscribe>
            <S.Email>
              <InputField
                label={emailLabel || t('globalNewsletter.emailLabel')}
                placeholder={emailPlaceholder || t('globalNewsletter.emailPlaceholder')}
                name="email"
                register={register}
                required={NewsletterSchema.email.required}
                pattern={NewsletterSchema.email.pattern}
                showError
                error={errors.email && errors.email.message}
                testId="newsletter-email"
                onFocus={() => setVisiblePrivacyCheckbox(true)}
                onBlur={() =>
                  setVisiblePrivacyCheckbox(
                    !!(
                      errors.email ||
                      errors.preferences ||
                      errors.notification ||
                      getValues().notification ||
                      getValues().email
                    )
                  )
                }
              />
            </S.Email>
            <S.SubmitBtn
              label={subscribeBtn || t('globalNewsletter.subscribeBtn')}
              type="submit"
              disabled={isSubmitting}
              testId="newsletter-submit"
              data-cs-capture=""
            />
          </S.EmailSubscribe>
          {privacyPolicyEl && (
            <S.NotificationCheckBox $visible={visiblePrivacyCheckbox}>
              <Checkbox
                label={privacyPolicyEl}
                newsletter
                name="notification"
                register={register}
                required={NewsletterSchema.notification.required}
                error={errors.notification && errors.notification.message}
                testId="newsletter-privacy-policy"
                data-cs-capture=""
                isChecked={getValues().notification as 'true' | 'false'}
              />
            </S.NotificationCheckBox>
          )}
        </S.Form>
      )}
    </S.Wrapper>
  );
};
