import { useTranslation } from 'next-i18next';
import type { GlobalCookieWallSettings as GlobalCookieWallSettingsSchema } from '../../../amplienceTypes/schemas/imported/global-cookie-wall-settings-schema';
import { colors } from '../core/styles';
import PreferencesIcon from '../core/icons/PreferencesIcon';
import { S } from './styles';

type CookieWallElementProps = {
  changeSettingsHandler: () => void;
  refuseOptionalHandler: () => void;
  acceptAllHandler: () => void;
};

const AFFILIATES = '[affiliates]';
const CLICK_HERE = '[click here]';
const OUR_PARTNERS = '[our partners]';
const COOKIE_STATEMENT = '[cookie statement]';
const PRIVACY_STATEMENT = '[privacy statement]';

const CookieWallV2Element = ({
  changeSettingsHandler,
  refuseOptionalHandler,
  acceptAllHandler,
  titleV2,
  contentV2Paragraph1,
  affiliatesLink,
  affiliates,
  clickHereLink,
  clickHere,
  contentV2Paragraph2,
  ourPartnersLink,
  ourPartners,
  contentV2Paragraph3,
  cookieStatement,
  privacyStatementLink,
  privacyStatement,
  preferencesBtn,
  refuseOptionalBtn,
  acceptAllBtn,
}: CookieWallElementProps & Partial<GlobalCookieWallSettingsSchema>) => {
  const { t } = useTranslation('common', { keyPrefix: 'globalCookieWallSettings' });

  const contentV2Paragraph1Copy = contentV2Paragraph1;
  const contentV2Paragraph2Copy = contentV2Paragraph2;
  const contentV2Paragraph3Copy = contentV2Paragraph3;

  const contentV2Paragraph1HasAffiliates = contentV2Paragraph1Copy?.includes(AFFILIATES);
  const contentV2Paragraph1HasClickHere = contentV2Paragraph1Copy?.includes(CLICK_HERE);
  const contentV2Paragraph2HasOurPartners = contentV2Paragraph2Copy?.includes(OUR_PARTNERS);
  const contentV2Paragraph3HasCookieStatement = contentV2Paragraph3Copy?.includes(COOKIE_STATEMENT);
  const contentV2Paragraph3HasPrivacyStatement =
    contentV2Paragraph3Copy?.includes(PRIVACY_STATEMENT);

  return (
    <>
      {titleV2 && (
        <S.CookieV2Title component="h1" variant="h3" tabIndex={0}>
          {titleV2}
        </S.CookieV2Title>
      )}

      <S.CookieContent component="div" variant="body">
        <S.Text>
          {contentV2Paragraph1Copy?.split(AFFILIATES)[0]}

          {contentV2Paragraph1HasAffiliates && (
            <S.LinkV2 href={affiliatesLink || t('affiliatesLink')}>
              {affiliates || t('affiliates')}
            </S.LinkV2>
          )}

          {contentV2Paragraph1Copy?.split(AFFILIATES)[1]?.split(CLICK_HERE)[0]}

          {contentV2Paragraph1HasClickHere && (
            <S.LinkV2 href={clickHereLink || t('clickHereLink')}>
              {clickHere || t('clickHere')}
            </S.LinkV2>
          )}

          {contentV2Paragraph1Copy?.split(AFFILIATES)[1]?.split(CLICK_HERE)[1]}
          {contentV2Paragraph2Copy?.split(OUR_PARTNERS)[0]}

          {contentV2Paragraph2HasOurPartners && (
            <S.LinkV2 href={ourPartnersLink || t('ourPartnersLink')}>
              {ourPartners || t('ourPartners')}
            </S.LinkV2>
          )}

          {contentV2Paragraph2Copy?.split(OUR_PARTNERS)[1]}
          {contentV2Paragraph3Copy?.split(COOKIE_STATEMENT)[0]}

          {contentV2Paragraph3HasCookieStatement && (
            <S.LinkV2 href="/cookie-statement">{cookieStatement || t('cookieStatement')}</S.LinkV2>
          )}

          {contentV2Paragraph3Copy?.split(COOKIE_STATEMENT)[1]?.split(PRIVACY_STATEMENT)[0]}

          {contentV2Paragraph3HasPrivacyStatement && (
            <S.LinkV2 href={privacyStatementLink || t('privacyStatementLink')}>
              {privacyStatement || t('privacyStatement')}
            </S.LinkV2>
          )}

          {contentV2Paragraph3Copy?.split(COOKIE_STATEMENT)[1]?.split(PRIVACY_STATEMENT)[1]}
        </S.Text>
      </S.CookieContent>

      <S.ButtonsV2>
        <S.PreferencesButton
          onClick={changeSettingsHandler}
          data-testid="cookie-wall-change-settings"
        >
          <S.IconWrapper>
            <PreferencesIcon color={colors.BLUE_CHARCOAL} />
          </S.IconWrapper>
          {preferencesBtn || t('preferencesBtn')}
        </S.PreferencesButton>

        <S.QuickButtons>
          <S.CookieButton
            label={refuseOptionalBtn || t('refuseOptionalBtn')}
            ordinal="secondary"
            onClick={refuseOptionalHandler}
            testId="cookie-wall-refuse-optional"
          />
          <S.CookieButton
            label={acceptAllBtn || t('acceptAllBtn')}
            ordinal="secondary"
            onClick={acceptAllHandler}
            testId="cookie-wall-accept"
          />
        </S.QuickButtons>
      </S.ButtonsV2>
    </>
  );
};

export default CookieWallV2Element;
