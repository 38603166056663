import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const SocialLinkedinInvertedIcon = ({
  color = colors.BLACK,
  hoverColor,
}: IconProps): ReactElement => (
  <Svg viewBox="0 0 1024 1024" $hoverColor={hoverColor}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill={color}>
        <path d="M512 1024A512 512 0 1 0 512 0a512 512 0 0 0 0 1024ZM296 240c-36 0-72 27-72 63s36 63 72 63 72-27 72-63-36-63-72-63Zm-62 544h125V416H234v368Zm422-377c-66 0-92 37-108 63v-54H422v368h126V560c0-24 31-58 65-58 44 0 61 34 61 85v197h125V569c0-116-62-162-143-162Z" />
      </g>
    </g>
  </Svg>
);

export default SocialLinkedinInvertedIcon;
