import type { ReactElement } from 'react';
import Script from 'next/script';
import { humanScriptIds } from '../../../utilities/constants/human';
import logger from '../../../utilities/logger';

export const HumanHeadScript = (): ReactElement => {
  if (!process.env.ENVIRONMENT) {
    logger.warn('ENVIRONMENT variable not set in env');

    return <></>;
  }

  const humanScriptId = humanScriptIds[process.env.ENVIRONMENT];

  if (!humanScriptId) {
    logger.warn("Can't find the relevant HUMAN script id");

    return <></>;
  }

  const HUMANScriptUrl = `//client.px-cloud.net/${humanScriptId}/main.min.js`;

  return <Script id="human-head-script" src={HUMANScriptUrl} async />;
};
