import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const SocialYoutubeInvertedIcon = ({
  color = colors.BLACK,
  hoverColor,
}: IconProps): ReactElement => (
  <Svg viewBox="0 0 1024 1024" $hoverColor={hoverColor}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill={color}>
        <path d="m453 586 156-80-156-82M512 0a512 512 0 1 0 0 1024A512 512 0 0 0 512 0zm289 533c0 47-6 94-6 94s-6 39-23 57c-22 23-46 23-58 24-80 6-201 6-201 6s-150-1-196-6c-13-2-42-1-64-24-17-18-23-57-23-57s-6-47-6-94v-43c0-47 6-94 6-94s6-39 23-57c22-23 46-23 58-24 80-6 201-6 201-6h1s121 0 201 6c12 1 36 1 58 24 17 18 23 57 23 57s6 47 6 94v43z" />
      </g>
    </g>
  </Svg>
);

export default SocialYoutubeInvertedIcon;
