// Smart banner app related
export const parseSmartAppBanner = (store: string, locale: string) => {
  if (!store || !locale) {
    return '';
  }

  const smartAppData = {
    store,
    host: 'www.g-star.com',
    locale,
  };

  return `https://${store}.api.highstreetapp.com/smartbanner/${window.btoa(
    JSON.stringify(smartAppData)
  )}`;
};
export const smartAppBannerId = 'highstreet-smart-app-banner';
export const smartAppBannerCloseId = 'hsb-rounded-close-button';
