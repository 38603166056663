import type { ReactElement } from 'react';
import styled from 'styled-components';
import { media, colors } from '../../core/styles';
import HeartEmptyIcon from '../../core/icons/HeartEmptyIcon';
import HeartFullIcon from '../../core/icons/HeartFullIcon';
import { Typography } from '../../core/typography/Typography';
import { useWishlistContext } from '../../../../utilities/context/dynamic/WishlistContext';
import { useDataLayerContext } from '../../../../utilities/context/static/DataLayerContext';

interface WishlistButtonProps {
  onWishlistHover: (hovered: boolean) => void;
  wishlistHovered: boolean;
}

const S = {
  WishListButton: styled.button`
    height: 54px;
    text-decoration: none;
    display: flex;
    align-items: center;

    &:hover,
    &:focus {
      path {
        fill: ${colors.DARK_GREY};
      }

      span {
        color: ${colors.DARK_GREY};
      }
    }

    @media ${media.greaterThan('sm')} {
      padding: 0 10px;
    }
  `,

  Amount: styled(Typography)`
    color: ${colors.WHITE};
    padding-left: 4px;
  `,

  IconWrapper: styled.span`
    display: inline-block;
    width: 16px;
    height: 16px;

    @media ${media.greaterThan('sm')} {
      width: 18px;
      height: 18px;
    }
  `,
};

export const WishListButton = ({
  onWishlistHover,
  wishlistHovered,
}: WishlistButtonProps): ReactElement => {
  const { miniWishlist } = useWishlistContext();
  const { pushToDataLayer } = useDataLayerContext();

  const handleClick = () => {
    window.location.href = '/wishlist';
  };

  return (
    <S.WishListButton
      onMouseEnter={() => {
        onWishlistHover(true);
        pushToDataLayer({
          events: {
            category: 'wishlist',
            action: 'hover',
            label: 'open mini-wishlist',
          },
          event: 'mini-wishlist-open',
        });
      }}
      onMouseLeave={() => onWishlistHover(false)}
      onClick={handleClick}
      aria-label={`Wishlist contains ${miniWishlist?.products?.length || '0'} ${
        (miniWishlist?.products?.length || 0) === 1 ? 'item' : 'items'
      }`}
      aria-haspopup="true"
      aria-controls="wishlistHeader"
      aria-expanded={wishlistHovered}
      data-cs-capture=""
    >
      <S.IconWrapper data-testid="wishlist-icon">
        {miniWishlist?.products?.length ? (
          <HeartFullIcon color={colors.WHITE} />
        ) : (
          <HeartEmptyIcon color={colors.WHITE} />
        )}
      </S.IconWrapper>
      {miniWishlist?.products && miniWishlist.products.length > 0 && (
        <S.Amount component="span" variant="title" testId="wishlist-count" data-cs-capture="">
          {miniWishlist.products?.length}
        </S.Amount>
      )}
    </S.WishListButton>
  );
};
