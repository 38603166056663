import type { ReactElement } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { Typography } from '../../core/typography/Typography';
import { colors, media } from '../../core/styles';
import { GenericLink } from './GenericLink';
import type { NavigationItem } from '../../../../utilities/graphql/codegen';

const S = {
  Wrapper: styled.nav`
    text-align: left;

    @media ${media.greaterThan('sm')} {
      flex: 1;
      margin-inline: 5px;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  `,

  ListTitle: styled(Typography)<{ $active: boolean }>`
    font-size: 14px;
    line-height: 28px;
    font-weight: 700;
    text-transform: uppercase;
    color: ${colors.NERO_GREY};
    position: relative;
    padding-top: 7px;
    padding-bottom: 7px;

    @media ${media.greaterThan('sm')} {
      padding-top: 0;
      padding-bottom: 0;
    }

    @media ${media.lessThan('sm')} {
      cursor: pointer;

      &::before,
      &::after {
        position: absolute;
        top: 50%;
        content: ' ';
        right: 0;
        width: 12px;
        height: 2px;
        background-color: ${colors.ACCESSIBILITY_GREY};
      }

      &::before {
        transform: rotate(${({ $active }) => ($active ? '0' : '90deg')});
        transition: transform 0.25s ease-in-out;

        @media (prefers-reduced-motion) {
          transition: none;
        }
      }
    }
  `,

  LinkList: styled.div<{ $active: boolean }>`
    overflow: hidden;
    margin-top: 5px;
    max-height: ${({ $active }) => ($active ? '500px' : '0px')};
    transition: max-height 0.5s ease-in-out;

    @media (prefers-reduced-motion) {
      transition: none;
    }

    @media ${media.greaterThan('sm')} {
      max-height: inherit;
    }
  `,

  Anchor: styled.a`
    display: block;
    text-decoration: none;
    cursor: pointer;
    color: ${colors.ACCESSIBILITY_GREY};
    white-space: nowrap;
    line-height: 32px;
  `,
};

export const GenericLinkList = ({ label, children }: NavigationItem): ReactElement => {
  const [isActive, setIsActive] = useState(false);

  return (
    <S.Wrapper data-testid="footer-linklist">
      <S.ListTitle
        component="h2"
        variant="body"
        $active={isActive}
        onClick={() => {
          setIsActive(!isActive);
        }}
        testId="footer-linklist-title"
      >
        {label}
      </S.ListTitle>
      <S.LinkList $active={isActive} data-testid="footer-linklist-list">
        {children && children.map((link, index) => <GenericLink {...link} key={index} />)}
      </S.LinkList>
    </S.Wrapper>
  );
};
