import { Environments } from '.';

// HUMAN script ids
const humanProductionId = 'PXTNnBmqF5';
const humanStagingId = 'PXjOsI0AKr';

export const humanScriptIds: { [k: string]: string } = {
  [Environments.DEV]: humanStagingId,
  [Environments.TST]: humanStagingId,
  [Environments.ACC]: humanStagingId,
  [Environments.PRD]: humanProductionId,
};
